export class Jan {
  id?: string;
  companyId?: string;
  code?: string;
  name?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isDeleted?: boolean;

  constructor() {
    this.id = '';
    this.companyId = '';
    this.code = '';
    this.name = '';
  }
}
