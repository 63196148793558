import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { EnvironmentUrlService } from './../../shared/services/environment-url.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { ApiSearch } from './../../_interfaces/api-search.model';
import { Receive } from './../../_interfaces/receive.model';
import { Init } from './../../_interfaces/init.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ProcessListComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public apiSearch: ApiSearch = new ApiSearch();
  public receives: Receive[] = [];
  public rowSelected: number = -1;

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorMessage: string = '';
  public isDisp: boolean = false;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private envUrl: EnvironmentUrlService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public searchDisp() {
    this.isDisp = true;
  }

  public searchNoDisp() {
    this.isDisp = false;
  }

  public onClickRowSelected(i: number) {
    this.receives.map(row => row.selectedColor = '');
    this.receives[i].selectedColor = 'row-selected';
    this.rowSelected = i;
  }

  public processOrderClear() {
    this.apiSearch.processOrderStart = null;
    this.apiSearch.processOrderEnd = null;
  }

  public processCompleteClear() {
    this.apiSearch.processCompleteStart = null;
    this.apiSearch.processCompleteEnd = null;
  }

  public inchIdClear() {
    this.apiSearch.inchId = null;
  }

  public kubun1IdClear() {
    this.apiSearch.kubun1Id = null;
  }

  public kubun2IdClear() {
    this.apiSearch.kubun2Id = null;
  }

  public inventoryKubunIdClear() {
    this.apiSearch.inventoryKubunId = null;
  }

  public riKubunIdClear() {
    this.apiSearch.riKubunId = null;
  }

  public search() {
    this.isDisp = false;
    this.receives = [];
    this.errorMessage = '';
    this.getListProcesses();
  }

  public csvOrder() {
    this.errorMessage = '';
    this.getCsvInstruct();
  }

  public csvPiQr() {
    this.errorMessage = '';
    this.getCsvPiQr();
  }

  public move() {
    this.errorMessage = '';
    if (this.rowSelected == -1) {
      this.errorMessage = CONST.MESSAGE.PROCESS_LIST.NO_SELECTED;
      $('#errorModal').modal();
      return;
    }
    Common.id = this.receives[this.rowSelected].id;
    this.router.navigate([CONST.VIEW.PROCESS_INPUT]);
  }

  // Private Function ///////////////////////////////////////////////////////////
  private setParams(apiSearch: ApiSearch) {
    if (apiSearch.processOrderEnd) {
      apiSearch.processOrderEnd.setHours(23);
      apiSearch.processOrderEnd.setMinutes(59);
      apiSearch.processOrderEnd.setSeconds(59);
    }
    if (apiSearch.processCompleteEnd) {
      apiSearch.processCompleteEnd.setHours(23);
      apiSearch.processCompleteEnd.setMinutes(59);
      apiSearch.processCompleteEnd.setSeconds(59);
    }
    return apiSearch;
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.jans = init.jans;
      this.users = init.users;
      this.apiSearch.inventoryKubunId = 2;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getListProcesses() {
    let apiAddress: string = CONST.API.PROCESS.LIST;
    let params = this.setParams(this.apiSearch);
    params.baseId = Common.baseId;
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      let receives = res as Receive[];
      receives = receives.sort((r1, r2) => {
        if ((r1.process.createdAt > r2.process.createdAt)) {
          return 1;
        } else {
          return -1;
        }
      });
      this.receives = receives;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getCsvInstruct() {
    let apiAddress: string = CONST.API.PROCESS.CSV_INSTRUCT;
    let params = this.setParams(this.apiSearch);
    this.repository.postFile(apiAddress, params).subscribe(res => {
      let blob = new Blob([(<any>res)], {type: 'text/csv'});
      let dt = new Date();
      let y = dt.getFullYear();
      let m = ("00" + (dt.getMonth()+1)).slice(-2);
      let d = ("00" + dt.getDate()).slice(-2);
      let h = ("00" + dt.getHours()).slice(-2);
      let mi = ("00" + dt.getMinutes()).slice(-2);
      let s = ("00" + dt.getSeconds()).slice(-2);
      let now = y + m + d + h + mi + s;

      let csvFileName = `指示用_${now}.csv`;
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, csvFileName);
      } else {
        let link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', csvFileName);
        document.body.appendChild(link);
        link.click()
        link.remove();
      }
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getCsvPiQr() {
    var str = "";
  	str += "url\r\n";
    for (let i = 0; i < this.receives.length; i++) {
      str += this.envUrl.urlAddress + CONST.VIEW.SHIPMENT_DETAILE
      str += '/' + this.receives[i].id + '/'
      str += this.receives[i].shipment != null && this.receives[i].shipment.subNumber != null ? this.receives[i].shipment.subNumber : -1;
      str += "/9999\r\n";
    }

    let blob = new Blob([(<any>str)], {type: 'text/csv'});
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);
    let d = ("00" + dt.getDate()).slice(-2);
    let h = ("00" + dt.getHours()).slice(-2);
    let mi = ("00" + dt.getMinutes()).slice(-2);
    let s = ("00" + dt.getSeconds()).slice(-2);
    let now = y + m + d + h + mi + s;

    let csvFileName = `PI-QR用_${now}.csv`;
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, csvFileName);
    } else {
      let link = document.createElement('a');
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', csvFileName);
      document.body.appendChild(link);
      link.click()
      link.remove();
    }
  }
}
