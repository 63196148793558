export class Arrive {
  id?: string;
  baseId?: number;
  imagePath1?: string;
  imagePath2?: string;
  imagePath3?: string;
  imagePath4?: string;
  createdUserId?: string;
  createdAt?: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  isDeleted?: boolean;

  createdUserName?: string;
  updatedUserName?: string;

  constructor() {
    this.id = null;
    this.baseId = null;
    this.imagePath1 = null;
    this.imagePath2 = null;
    this.imagePath3 = null;
    this.imagePath4 = null;
    this.createdUserId = null;
    this.createdAt = null;
    this.updatedUserId = null;
    this.updatedAt = null;
    this.isDeleted = null;
    this.createdUserName = null;
    this.updatedUserName = null;
  }
}
