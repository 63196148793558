import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.css']
})
export class ActionModalComponent implements OnInit {

  @Input() public modalHeaderText: string;
  @Input() public modalBodyText: string;
  @Input() public editButtonText: string;
  @Input() public deleteButtonText: string;

  @Output() public redirectOnEdit = new EventEmitter();
  @Output() public redirectOnDelete = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public emmitEditEvent(){
    this.redirectOnEdit.emit();
  }

  public emmitDeleteEvent(){
    this.redirectOnDelete.emit();
  }
}
