export class Shipment {
  id?: string;
  subNumber?: number;
  orderNo?: string;
  orderQuantity?: number;
  kubunId?: number;
  baseId?: number;
  stateId?: number;
  orderAt?: Date;
  shippingAt?: Date;
  fecesId?: number;
  invoiceNo?: string;
  toPostalCode?: string;
  toName?: string;
  toAddress?: string;
  inputterMemo?: string;
  invoiceMemo?: string;
  createdUserId?: string;
  createdAt?: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  isDeleted?: boolean;

  allQuantity?: number;
  kubunName?: string;
  baseName?: string;
  stateName?: string;
  fecesName?: string;
  createdUserName?: string;
  updatedUserName?: string;

  constructor() {
    this.id = null;
    this.subNumber = null;
    this.orderNo = null;
    this.orderQuantity = null;
    this.kubunId = null;
    this.baseId = null;
    this.stateId = null;
    this.orderAt = null;
    this.shippingAt = null;
    this.fecesId = null;
    this.invoiceNo = null;
    this.toPostalCode = null;
    this.toName = null;
    this.toAddress = null;
    this.inputterMemo = null;
    this.invoiceMemo = null;
    this.createdUserId = null;
    this.createdAt = null;
    this.updatedUserId = null;
    this.updatedAt = null;
    this.isDeleted = null;
    this.allQuantity = null;
    this.kubunName = null;
    this.baseName = null;
    this.stateName = null;
    this.fecesName = null;
    this.createdUserName = null;
    this.updatedUserName = null;
  }
}
