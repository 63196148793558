import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { Init } from './../../_interfaces/init.model';
import { Receive } from './../../_interfaces/receive.model';
import { Shipment } from './../../_interfaces/shipment.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';

@Component({
  selector: 'app-shipment-input',
  templateUrl: './shipment-input.component.html',
  styleUrls: ['./shipment-input.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ShipmentInputComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public receive: Receive = new Receive();

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorHeaderText: string = '';
  public errorMessage: string = '';
  public isMove: boolean = false;
  public isSet: boolean = false;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
      if (Common.id != '') {
        this.getDetailShipment();
      }
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public kubunIdClear() {
    this.receive.shipment.kubunId = null;
  }

  public baseIdClear() {
    this.receive.shipment.baseId = null;
  }

  public toPostalCodeClear() {
    this.receive.shipment.toName = null;
    this.receive.shipment.toPostalCode = null;
    this.receive.shipment.toAddress = null;
    this.receive.shipment.invoiceNo = null;
  }

  public orderQuantityClear() {
    this.receive.shipment.orderQuantity = null;
  }

  public fecesIdClear() {
    this.receive.shipment.fecesId = null;
  }

  public inputterMemoClear() {
    this.receive.shipment.inputterMemo = null;
  }

  public invoiceMemoClear() {
    this.receive.shipment.invoiceMemo = null;
  }

  public kubunChange() {
    this.isMove = false;
    this.isSet = false;
    var elem = <HTMLInputElement> document.getElementById('kubun');
    if (elem.value == '1') {
      this.receive.shipment.baseId = null;
    } else if (elem.value == '2') {
      this.receive.shipment.baseId = null;
    } else if (elem.value == '3') {
      this.isSet = true;
      this.receive.shipment.baseId = null;
      this.receive.shipment.toName = null;
      this.receive.shipment.toPostalCode = null;
      this.receive.shipment.toAddress = null;
      this.receive.shipment.invoiceNo = null;
      this.receive.shipment.orderQuantity = null;
      this.receive.shipment.inputterMemo = null;
      this.receive.shipment.invoiceMemo = null;
      this.receive.shipment.fecesId = null;
    } else if (elem.value == '4') {
      this.isMove = true;
    }
  }

  public save() {
    this.errorMessage = '';
    if (this.inputCheck()) {
      this.postCreateShipment();
    }
  }

  public import() {
    let input = document.getElementById('inputFile');
    input.click();
  }

  public async importFile() {
    let input = document.getElementById('inputFile') as HTMLInputElement;
    try {
      if (input.files.length > 0) {
        let header = ['','','','toName','','toPostalCode','toAddress1','toAddress2','invoiceNo','','','','','','','','','','','','',''];
        let items = await Common.csvImport(header, input.files[0]);
        if (items.length == 1) {
          if (items[0]['toName'] && items[0]['toPostalCode'] && items[0]['toAddress1'] && items[0]['toAddress2'] && items[0]['invoiceNo']) {
            this.receive.shipment.toName = items[0]['toName'];
            this.receive.shipment.toPostalCode = items[0]['toPostalCode'];
            this.receive.shipment.toAddress = items[0]['toAddress1'] + items[0]['toAddress2'];
            this.receive.shipment.invoiceNo = items[0]['invoiceNo'];
          } else {
            this.errorHeaderText = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
            this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NO_CSV_IMPORT;
            $('#errorModal').modal();
            return;
          }
        } else {
          this.errorHeaderText = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
          this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NO_CSV_IMPORT;
          $('#errorModal').modal();
        }
      }
    } catch (e) {
      this.errorHeaderText = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
      this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NO_CSV_IMPORT;
      $('#errorModal').modal();
    }
    input.value = '';
  }

  // Private Function ///////////////////////////////////////////////////////////
  private inputCheck(): boolean {
    this.errorMessage = '';
    if (!Common.isNumberValue(this.receive.shipment.kubunId)) {
      this.errorMessage = CONST.MESSAGE.SHIPMENT_INPUT.NONE_KUBUN;
      $('#errorModal').modal();
    } else if (!Common.isNumberValue(this.receive.shipment.orderQuantity)) {
      this.errorMessage = CONST.MESSAGE.SHIPMENT_INPUT.NONE_QUANTITY;
      $('#errorModal').modal();
    } else if (this.receive.shipment.orderQuantity != null) {
      if ((this.receive.quantity - this.receive.shipment.allQuantity) < this.receive.shipment.orderQuantity) {
        this.errorMessage = CONST.MESSAGE.SHIPMENT_INPUT.OVER_QUANTITY;
        $('#errorModal').modal();
      } else if (this.receive.shipment.orderQuantity <= 0) {
        this.errorMessage = CONST.MESSAGE.SHIPMENT_INPUT.ERROR_QUANTITY;
        $('#errorModal').modal();
      }
      return this.errorMessage == '';
    }
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.jans = init.jans;
      this.users = init.users;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  public getDetailShipment() {
    let apiAddress: string = CONST.API.SHIPMENT.DETAIL;
    let params = {
      id: Common.id,
      subNumber: Common.subNumber
    }
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      let receive = res as Receive;
      this.receive = receive;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private postCreateShipment() {
    let params: Shipment = this.receive.shipment;
    let apiAddress: string = CONST.API.SHIPMENT.CREATE;
    if (this.receive.shipment.id != null) {
      apiAddress = CONST.API.SHIPMENT.UPDATE;
      params.stateId = this.isMove ? 2 : 1;
      params.updatedUserId = Common.userId;
    } else {
      params.id = this.receive.id;
      params.stateId = this.isMove ? 2 : 1;
      params.createdUserId = Common.userId;
      params.updatedUserId = Common.userId;
      params.baseId = Common.baseId;
    }
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      this.receive = new Receive();
      this.isMove = false;
      this.isSet = false;
      $('#successModal').modal();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
