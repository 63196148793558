import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import is from 'is_js';

import { RepositoryService } from './../../shared/services/repository.service';
import { EnvironmentUrlService } from './../../shared/services/environment-url.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { User } from './../../_interfaces/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public userId: string = '';
  public errorMessage: string = '';

  constructor(
    private repository: RepositoryService,
    private router: Router,
    private envUrl: EnvironmentUrlService
  ) { }

  ngOnInit() {
    this.isHorizontal = (window.orientation == 90);
    Common.userId = '';
    Common.baseId = -1;
    Common.baseName = '';
    Common.isAdmin = false;
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action //////////////////////////////////////////////////////////////
  public login() {
    this.errorMessage = '';
    if (this.userId == null || this.userId == '') {
      this.errorMessage = CONST.MESSAGE.LOGIN.NO_USERID;
      $('#errorModal').modal();
      return;
    } else if (this.userId == '9999') {
      this.errorMessage = CONST.MESSAGE.LOGIN.GUEST;
      $('#errorModal').modal();
      return;
    } else if (this.userId.length != 4) {
      this.errorMessage = CONST.MESSAGE.LOGIN.LENGTH;
      $('#errorModal').modal();
      return;
    }
    this.getDetail(this.userId);
  }

  // Web Api //////////////////////////////////////////////////////////////////
  private getDetail(id: string) {
    let apiAddress: string = CONST.API.USER.DETAIL;
    let params = {id: id};
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      let user = res as User;
      if (this.userId == user.id) {
        Common.userId = user.id;
        Common.baseId = user.baseId;
        Common.baseName = user.baseName;
        Common.isAdmin = user.isAdmin;
        Common.init = false;
        this.router.navigate([CONST.VIEW.RECEIVE_LIST]);
      } else {
        Common.userId = '';
        this.errorMessage = CONST.MESSAGE.LOGIN.DISCORD;
        $('#errorModal').modal();
      }
    }, error => {
      this.errorMessage = CONST.MESSAGE.LOGIN.DISCORD;
      $('#errorModal').modal();
    });
  }
}
