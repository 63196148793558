export class Common {
  private static _userId: string = '';
  private static _id: string = '';
  private static _subNumber: number = -1;
  private static _baseId: number = -1;
  private static _baseName: string = '';
  private static _isAdmin: boolean = false;
  private static _init: boolean = false;

  static get userId(): string {
    return this._userId;
  }
  static set userId(value: string) {
    this._userId = value;
  }
  static get id(): string {
    return this._id;
  }
  static set id(value: string) {
    this._id = value;
  }
  static get subNumber(): number {
    return this._subNumber;
  }
  static set subNumber(value: number) {
    this._subNumber = value;
  }
  static get baseId(): number {
    return this._baseId;
  }
  static set baseId(value: number) {
    this._baseId = value;
  }
  static get baseName(): string {
    return this._baseName;
  }
  static set baseName(value: string) {
    this._baseName = value;
  }
  static get isAdmin(): boolean {
    return this._isAdmin;
  }
  static set isAdmin(value: boolean) {
    this._isAdmin = value;
  }
  static get init(): boolean {
    return this._init;
  }
  static set init(value: boolean) {
    this._init = value;
  }

  public static isNumberValue(value: number): boolean {
    if (value == null) {
      return false;
    } else if (value.toString() == "") {
      return false;
    }
    return true;
  }

  public static isStringValue(value: string): boolean {
    if (value == null) {
      return false;
    } else if (value.toString() == "") {
      return false;
    }
    return true;
  }

  public static isDateValue(value: Date): boolean {
    if (value == null) {
      return false;
    }
    return true;
  }

  public static imageDecode(img: HTMLCanvasElement): string {
    if (img == null) return null;
    return img.toDataURL("image/jpeg");
  }

  public static csvImport(header: string[], file: File): any {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      let items: any[] = [];
      fileReader.readAsText(file, "Shift_JIS");
      fileReader.onload = () => {
        let fileResult = fileReader.result.split('\r\n');
        fileResult.shift();
        for (let i = 0; i < fileResult.length; i++) {
          if (fileResult[i] != '') {
            let result = {};
            let datas = fileResult[i].split(',');
            for (const index in datas) {
              if (header[index] != '') {
                result[header[index]] = datas[index].replace(/"/g, '');
              }
            }
            items.push(result);
          }
        }
        resolve(items);
      }
    });
  }

  public static getStringFromDate(date: Date, formatStr: string) {
    formatStr = formatStr.replace(/YYYY/g, ("0" + date.getFullYear()).slice(-4));
    formatStr = formatStr.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
    formatStr = formatStr.replace(/DD/g, ("0" + date.getDate()).slice(-2));
    formatStr = formatStr.replace(/hh/g, ("0" + date.getHours()).slice(-2));
    formatStr = formatStr.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
    formatStr = formatStr.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
    return formatStr;
  }
}
