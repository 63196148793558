export class CONST {
  public static VERSION = 'ver.0.0.0';
  public static DEBUG = false;

  public static VIEW = {
    //// Main /////////////////////////////////////////////
    LOGIN: '/login',
    ARRIVE_MOVE: '/arrive-move',
    ARRIVE_QR: '/arrive-qr',
    PROCESS_LIST: '/process-list',
    PROCESS_INPUT: '/process-input',
    RECEIVE_JAN: '/receive-jan',
    RECEIVE_LIST: '/receive-list',
    RECEIVE_QR: '/receive-qr',
    SHIPMENT_LIST: '/shipment-list',
    SHIPMENT_INPUT: '/shipment-input',
    SHIPMENT_DETAILE: '/shipment-detail',
    STOCK_LIST: '/stock-list',
    STOCK_DETAILE: '/stock-detail',

    //// Maintenance //////////////////////////////////////
    USER_LIST: '/user-list',
    USER_MAINTENANCE: '/user-maintenance',
    COMPANY_LIST: '/company-list',
    COMPANY_MAINTENANCE: '/company-maintenance',
    JAN_LIST: '/jan-list',
    JAN_MAINTENANCE: '/jan-maintenance',
  }

  public static API = {
    //// Main /////////////////////////////////////////////
    INIT: {
      GET_DATA: 'api/init/get',                           //初期取得(GET)
    },
    RECEIVE: {
      LIST: 'api/receive/post/list',                  // 一覧取得(全て)(POST)
      CHECK: 'api/receive/post/check',                // チェック(POST)
      CSV_LIST: 'api/receive/post/csv-list',          // CSV出力(一覧)(POST)
      CSV_TAPE: 'api/receive/post/csv-tape',          // テプラ用CSV出力(SKU-ID,名称)(POST)
      CREATE: 'api/receive/post/create',              // 登録(POST)
    },
    ARRIVE: {
      DETAIL: 'api/arrive/post/detail',               // 詳細(POST)
      UPDATE: 'api/arrive/post/update',               // 登録(POST)
      MOVE: 'api/arrive/post/move',                   // 登録(POST)
    },
    STOCK: {
      LIST: 'api/stock/post/list',                    // 一覧(POST)
      DETAIL: 'api/stock/post/detail',                // 詳細(POST)
      UPDATE: 'api/stock/post/update',                // 修正(POST)
      CSV_LIST: 'api/stock/post/csv-list',            // 一覧(POST)
      CSV_DOWNLOAD: 'api/stock/post/zip-download',    // 画像ダウンロード(POST)
    },
    PROCESS: {
      LIST: 'api/process/post/list',                  // 一覧(POST)
      DETAIL: 'api/process/post/detail',             // 詳細(POST)
      CREATE: 'api/process/post/create',              // 登録(POST)
      UPDATE: 'api/process/post/update',              // 編集(POST)
      COMPLETE: 'api/process/post/complete',          // 加工完了(POST)
      CSV_INSTRUCT: 'api/process/post/csv-instruct',  // 指示用CSV(POST)
      CSV_PIQR: 'api/process/post/csv-piqr',          // PI-QR用CSV(POST)
    },
    SHIPMENT: {
      LIST: 'api/shipment/post/list',                 // 一覧(POST)
      DETAIL: 'api/shipment/post/detail',             // 詳細(POST)
      CSV_LIST: 'api/shipment/post/csv-list',         // CSV出力(一覧)(POST)
      CREATE: 'api/shipment/post/create',             // 登録(POST)
      UPDATE: 'api/shipment/post/update',             // 修正(POST)
    },
    USER: {
      LIST: 'api/user/get/list',                      // 一覧(GET)
      DETAIL: 'api/user/get/detail',                  // 詳細(GET)
      CREATE: 'api/user/post/create',                 // 登録(POST)
      UPDATE: 'api/user/post/update',                 // 更新(POST)
      DELETE: 'api/user/post/delete',                 // 削除(POST)
      ADMIN: 'api/user/post/admin',                   // 管理者(POST)
    },
    COMPANY: {
      LIST: 'api/company/get/list',                   // 一覧(POST)
      DETAIL: 'api/company/get/detail',               // 詳細(POST)
      CREATE: 'api/company/post/create',              // 登録(POST)
      UPDATE: 'api/company/post/update',              // 更新(POST)
      DELETE: 'api/company/post/delete',              // 削除(POST)
      PURCHASE: 'api/company/post/purchase',          // 購入元(POST)
      MAKER: 'api/company/post/maker',                // メーカー(POST)
    },
    JAN: {
      LIST: 'api/jan/get/list',                       // 一覧(POST)
      DETAIL: 'api/jan/get/detail',                   // 詳細(POST)
      CREATE: 'api/jan/post/create',                  // 登録(POST)
      CREATES: 'api/jan/post/creates',                // 登録(POST)
      UPDATE: 'api/jan/post/update',                  // 更新(POST)
      DELETE: 'api/jan/post/delete',                  // 削除(POST)
    }
  }

  public static MESSAGE = {
    //// Main /////////////////////////////////////////////
    LOGIN: {
      NO_USERID: 'ユーザーIDを入力してください。',
      DISCORD: 'ログインエラーです。',
      GUEST: 'ゲストでログインは出来ません。',
      LENGTH: 'ユーザーIDは4桁です。',
    },
    ARRIVE_MOVE: {
      NONE_ID: 'SKU-IDが未入力です。',
      NONE_LOCATION: '再保管ロケーションを入力してください。',
      NONE: '対象のSKU-IDは存在していません。',
      NONE_QUANTITY: '保管数が未入力です。',
      OVER_QUANTITY: '保管数が在庫数を超えています。',
    },
    ARRIVE_QR: {
      NONE_ID: 'SKU-IDが未入力です。',
      NONE_LOCATION: '再保管ロケーションを入力してください。',
      NONE: '対象のSKU-IDは存在していません。',
      DIS_BASE: '別拠点のSKU-IDです。',
    },
    PROCESS_LIST: {
      NO_SELECTED: '行が選択されていません。',
    },
    PROCESS_INPUT: {
      NONE_LOCATION: '再保管ロケーションを入力してください。',
    },
    RECEIVE_JAN: {
      NO_MATCH_CODE: 'JANコードが一致しません。',
      NONE_ID: 'SKU-IDが未入力です。',
      NONE_CATEGORY: 'カテゴリが未選択です。',
      NONE_NAME: '名称が未入力です。',
      NONE_INCH: 'インチが未選択です。',
      NONE_QUANTITY: '数が未入力です。',
      NONE_KUBUN_FIRST: '商品区分1が未選択です。',
      NONE_KUBUN_SECOND: '商品区分2が未選択です。',
      NONE_INVENTORY_KUBUN: '在庫区分が未選択です。',
      NONE_RI_KUBUN: '入出荷区分が未選択です。',
      NONE_LOCATION: '保管ロケーションが未入力です。',
      ERROR_QUANTITY: '数が0以下です。',
    },
    RECEIVE_LIST: {
      NO_SELECTED: '行が選択されていません。',
    },
    RECEIVE_QR: {
      NONE_ID: 'SKU-IDが未入力です。',
      NONE_CATEGORY: 'カテゴリが未選択です。',
      NONE_NAME: '名称が未入力です。',
      NONE_INCH: 'インチが未選択です。',
      NONE_QUANTITY: '数が未入力です。',
      NONE_KUBUN_FIRST: '商品区分1が未選択です。',
      NONE_KUBUN_SECOND: '商品区分2が未選択です。',
      NONE_INVENTORY_KUBUN: '在庫区分が未選択です。',
      NONE_RI_KUBUN: '入出荷区分が未選択です。',
      NONE_LOCATION: '保管ロケーションが未入力です。',
      ERROR_QUANTITY: '数が0以下です。',
    },
    SHIPMENT_LIST: {
      NO_SELECTED: '行が選択されていません。',
    },
    SHIPMENT_INPUT: {
      NONE_KUBUN: '出荷区分が未選択です。',
      NONE_QUANTITY: '出荷指示数が未入力です。',
      OVER_QUANTITY: '出荷数が在庫数を超えています。',
      ERROR_QUANTITY: '出荷数が0以下です。',
    },
    SHIPMENT_DETAILE: {
    },
    STOCK_LIST: {
      NO_SELECTED: '行が選択されていません。',
      NO_KUBUN: '「再塗装品」且つ「加工前」のみ加工指示が可能です。',
    },
    STOCK_DETAILE: {
    },

    //// Maintenance //////////////////////////////////////
    USER_LIST: {
    },
    USER_MAINTENANCE: {
      NONE_ID: 'IDが入力されていません。',
      NONE_NAME: '名称が入力されていません。',
      NONE_BASE_ID: '拠点が選択されていません',
      DUPLICATE: 'IDが重複しています。',
    },
    COMPANY_LIST: {
    },
    COMPANY_MAINTENANCE: {
      NONE_ID: 'IDが入力されていません。',
      NONE_NAME: '名称が入力されていません。',
      DUPLICATE: 'IDが重複しています。',
    },
    JAN_LIST: {
      NO_ROW: '読み込んだCSVファイルが0行です。',
      NO_TWO_COL: '対象のCSVが2列ではありません。',
      ERROR_ROW: '以下のコードが重複しているため、取り込めませんでした。',
    },
    JAN_MAINTENANCE: {
      NONE_ID: 'IDが入力されていません。',
      NONE_COMPANY_ID: 'メーカーが選択されていません。',
      NONE_CODE: 'JANが入力されていません。',
      NONE_NAME: '名称が入力されていません。',
      DUPLICATE: 'IDが重複しています。',
    },

    //// Modal /////////////////////////////////////////////
    MODAL_TITLE: {
      NONE_ROW: '選択エラー',
      IS_DELETED: '削除エラー',
      IS_DISPLAY: '復元エラー',
      NO_CSV_IMPORT: '取込エラー',
    },
    MODAL_MESSAGE: {
      NONE_ROW: '対象の行が選択されていません。',
      IS_DELETED: '対象の行は既に削除済みです。',
      IS_DISPLAY: '対象の行は復元する必要がありません。',
      NO_CSV_IMPORT: '対象ファイルが読込めませんでした。',
    }
  }

  public static MODE = {
    CREATE: '1',
    EDIT: '2',
    DELETE: '3',
  }

  public static MENU = {
    IN: 'in',
    OUT: 'out',
  }

  public static KANA_MAP = {
     "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
     "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
     "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
     "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
     "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
     "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
     "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
     "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
     "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
     "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
     "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
     "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
     "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
     "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
     "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
     "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
     "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
     "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
     "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･"
   }
}
