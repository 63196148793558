import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { Init } from './../../_interfaces/init.model';
import { Receive } from './../../_interfaces/receive.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';

@Component({
  selector: 'app-shipment-detail',
  templateUrl: './shipment-detail.component.html',
  styleUrls: ['./shipment-detail.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ShipmentDetailComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public receive: Receive = new Receive();

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorMessage: string = '';
  public isAdmin = true;
  public userId = '';

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
      if (Common.id != '') {
        Common.isAdmin = true;
        this.getDetailShipment();
      }
    } else {
      this.route.params.subscribe(params => {
        if (params['id'] != null) {
          if (params['user-id'] == '9999') {
            this.isAdmin = false;
            this.userId = params['user-id'];
            Common.id = params['id'];
            Common.subNumber = params['sub-number'];
            Common.userId = params['user-id'];
            Common.baseId = -1;
            Common.baseName = '';
            Common.isAdmin = false;
            this.getDetailShipment();
          } else {
            this.router.navigate(['404']);
          }
        } else {
          this.router.navigate([CONST.VIEW.LOGIN]);
        }
      });
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
    setTimeout(() => {
      this.setImages();
    }, 500);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public back() {
    Common.id = '';
    this.router.navigate([CONST.VIEW.SHIPMENT_LIST]);
  }

  // Private Function ///////////////////////////////////////////////////////////
  private setImages() {
    if (this.receive.arrive.imagePath1 != null && this.receive.arrive.imagePath1 != '') {
      let aImg1 = <HTMLImageElement> document.getElementById('aImg1');
      aImg1.src = this.receive.arrive.imagePath1;
    }
    if (this.receive.arrive.imagePath2 != null && this.receive.arrive.imagePath2 != '') {
      let aImg2 = <HTMLImageElement> document.getElementById('aImg2');
      aImg2.src = this.receive.arrive.imagePath2;
    }
    if (this.receive.arrive.imagePath3 != null && this.receive.arrive.imagePath3 != '') {
      let aImg3 = <HTMLImageElement> document.getElementById('aImg3');
      aImg3.src = this.receive.arrive.imagePath3;
    }
    if (this.receive.arrive.imagePath4 != null && this.receive.arrive.imagePath4 != '') {
      let aImg4 = <HTMLImageElement> document.getElementById('aImg4');
      aImg4.src = this.receive.arrive.imagePath4;
    }

    if (this.receive.process.imagePath1 != null && this.receive.process.imagePath1 != '') {
      let pImg1 = <HTMLImageElement> document.getElementById('pImg1');
      pImg1.src = this.receive.process.imagePath1;
    }
    if (this.receive.process.imagePath2 != null && this.receive.process.imagePath2 != '') {
      let pImg2 = <HTMLImageElement> document.getElementById('pImg2');
      pImg2.src = this.receive.process.imagePath2;
    }
    if (this.receive.process.imagePath3 != null && this.receive.process.imagePath3 != '') {
      let pImg3 = <HTMLImageElement> document.getElementById('pImg3');
      pImg3.src = this.receive.process.imagePath3;
    }
    if (this.receive.process.imagePath4 != null && this.receive.process.imagePath4 != '') {
      let pImg4 = <HTMLImageElement> document.getElementById('pImg4');
      pImg4.src = this.receive.process.imagePath4;
    }
    if (this.receive.process.imagePath5 != null && this.receive.process.imagePath5 != '') {
      let pImg5 = <HTMLImageElement> document.getElementById('pImg5');
      pImg5.src = this.receive.process.imagePath5;
    }
    if (this.receive.process.imagePath6 != null && this.receive.process.imagePath6 != '') {
      let pImg6 = <HTMLImageElement> document.getElementById('pImg6');
      pImg6.src = this.receive.process.imagePath6;
    }
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.jans = init.jans;
      this.users = init.users;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  public getDetailShipment() {
    let apiAddress: string = CONST.API.SHIPMENT.DETAIL;
    let params = {
      id: Common.id,
      subNumber: Common.subNumber
    }
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      let receive = res as Receive;
      this.receive = receive;
      this.setImages();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
