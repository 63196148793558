import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentUrlService } from './environment-url.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(
    private http: HttpClient,
    private envUrl: EnvironmentUrlService
  ) { }


  public post(route: string, body){
    return this.http.post(this.createCompleteRoute(route, this.envUrl.urlAddress), body, this.generateHeaders());
  }

  public getData(route: string) {
    return this.http.get(this.createCompleteRoute(route, this.envUrl.urlAddress));
  }

  public getFile(route: string) {
    return this.http.get(this.createCompleteRoute(route, this.envUrl.urlAddress),
          {
            headers: {
            'Content-Type': 'application/json',
            },
            responseType: 'blob'
          }
    );
  }

  public postFile(route: string, body) {
    return this.http.post(this.createCompleteRoute(route, this.envUrl.urlAddress), body,
      {
        headers: {
        'Content-Type': 'application/json',
        },
        responseType: 'blob'
      });
  }

  public create(route: string, body) {
    return this.http.post(this.createCompleteRoute(route, this.envUrl.urlAddress), body, this.generateHeaders());
  }

  public update(route: string, body){
    return this.http.put(this.createCompleteRoute(route, this.envUrl.urlAddress), body, this.generateHeaders());
  }

  public delete(route: string){
    return this.http.delete(this.createCompleteRoute(route, this.envUrl.urlAddress));
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
  }
}
