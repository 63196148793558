import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';
import jsQR from "jsqr";

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { Init } from './../../_interfaces/init.model';
import { Receive } from './../../_interfaces/receive.model';
import { ApiProcessCreate } from './../../_interfaces/api-process-create.model';
import { ApiProcessUpdate } from './../../_interfaces/api-process-update.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';

@Component({
  selector: 'app-process-input',
  templateUrl: './process-input.component.html',
  styleUrls: ['./process-input.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ProcessInputComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public id: string = Common.id;
  public changeLocation: string = '';
  public receive: Receive = new Receive();
  public image1Flag: boolean = false;
  public image2Flag: boolean = false;
  public image3Flag: boolean = false;
  public image4Flag: boolean = false;
  public image5Flag: boolean = false;
  public image6Flag: boolean = false;

  public editInventoryKubunId: number = -1;
  public editLocation: string = '';

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorMessage: string = '';
  public cameraFunction;
  public qrLocationFlag: boolean = false;
  public picNumber: number = -1;
  public picFlag: boolean = false;
  public isAdminFlag: boolean = false;
  public isEdit: boolean = false;
  public stream;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.getDetailProcess();
      this.isHorizontal = (window.orientation == 90);
      if (Common.baseId == 3) {
        this.isAdminFlag = true;
      }
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    let canvBack1 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack2 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack3 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack4 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack5 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack6 = <HTMLCanvasElement> document.createElement("canvas");
    const contextBack1 = canvBack1.getContext("2d");
    const contextBack2 = canvBack2.getContext("2d");
    const contextBack3 = canvBack3.getContext("2d");
    const contextBack4 = canvBack4.getContext("2d");
    const contextBack5 = canvBack5.getContext("2d");
    const contextBack6 = canvBack6.getContext("2d");
    if (this.image1Flag) {
      let canv1 = <HTMLCanvasElement> document.querySelector("#picture1");
      canvBack1.width = canv1.width;
      canvBack1.height = canv1.height;
      contextBack1.drawImage(canv1, 0, 0, canv1.width, canv1.height);
    }
    if (this.image2Flag) {
      let canv2 = <HTMLCanvasElement> document.querySelector("#picture2");
      canvBack2.width = canv2.width;
      canvBack2.height = canv2.height;
      contextBack2.drawImage(canv2, 0, 0, canv2.width, canv2.height);
    }
    if (this.image3Flag) {
      let canv3 = <HTMLCanvasElement> document.querySelector("#picture3");
      canvBack3.width = canv3.width;
      canvBack3.height = canv3.height;
      contextBack3.drawImage(canv3, 0, 0, canv3.width, canv3.height);
    }
    if (this.image4Flag) {
      let canv4 = <HTMLCanvasElement> document.querySelector("#picture4");
      canvBack4.width = canv4.width;
      canvBack4.height = canv4.height;
      contextBack4.drawImage(canv4, 0, 0, canv4.width, canv4.height);
    }
    if (this.image5Flag) {
      let canv5 = <HTMLCanvasElement> document.querySelector("#picture5");
      canvBack5.width = canv5.width;
      canvBack5.height = canv5.height;
      contextBack5.drawImage(canv5, 0, 0, canv5.width, canv5.height);
    }
    if (this.image6Flag) {
      let canv6 = <HTMLCanvasElement> document.querySelector("#picture6");
      canvBack6.width = canv6.width;
      canvBack6.height = canv6.height;
      contextBack6.drawImage(canv6, 0, 0, canv6.width, canv6.height);
    }

    this.isHorizontal = (window.orientation == 90);
    setTimeout(() => {
      if (this.image1Flag) {
        let newCanv1 = <HTMLCanvasElement> document.querySelector("#picture1");
        let newContext1 = newCanv1.getContext("2d");
        newContext1.drawImage(canvBack1, 0, 0, canvBack1.width, canvBack1.height, 0, 0, newCanv1.width, newCanv1.height);
      }
      if (this.image2Flag) {
        let newCanv2 = <HTMLCanvasElement> document.querySelector("#picture2");
        let newContext2 = newCanv2.getContext("2d");
        newContext2.drawImage(canvBack2, 0, 0, canvBack2.width, canvBack2.height, 0, 0, newCanv2.width, newCanv2.height);
      }
      if (this.image3Flag) {
        let newCanv3 = <HTMLCanvasElement> document.querySelector("#picture3");
        let newContext3 = newCanv3.getContext("2d");
        newContext3.drawImage(canvBack3, 0, 0, canvBack3.width, canvBack3.height, 0, 0, newCanv3.width, newCanv3.height);
      }
      if (this.image4Flag) {
        let newCanv4 = <HTMLCanvasElement> document.querySelector("#picture4");
        let newContext4 = newCanv4.getContext("2d");
        newContext4.drawImage(canvBack4, 0, 0, canvBack4.width, canvBack4.height, 0, 0, newCanv4.width, newCanv4.height);
      }
      if (this.image5Flag) {
        let newCanv5 = <HTMLCanvasElement> document.querySelector("#picture5");
        let newContext5 = newCanv5.getContext("2d");
        newContext5.drawImage(canvBack5, 0, 0, canvBack5.width, canvBack5.height, 0, 0, newCanv5.width, newCanv5.height);
      }
      if (this.image6Flag) {
        let newCanv6 = <HTMLCanvasElement> document.querySelector("#picture6");
        let newContext6 = newCanv6.getContext("2d");
        newContext6.drawImage(canvBack6, 0, 0, canvBack6.width, canvBack6.height, 0, 0, newCanv6.width, newCanv6.height);
      }
      this.setImages();
    }, 500);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public inputRemarksClear() {
    if (!this.isAdminFlag) return;
    this.receive.process.remarks = '';
  }

  public inputLocationClear() {
    if (!this.isAdminFlag) return;
    this.changeLocation = '';
  }

  public onClickLocationQr() {
    this.qrFlagInit();
    this.qrLocationFlag = true;
    this.cameraStart();
  }

  public cameraCancel() {
    this.picNumber = -1;
    this.qrFlagInit();
  }

  public cameraPicStart(value: number) {
    if (!this.isAdminFlag) return;
    if (this.receive.process.userId) return;
    this.qrFlagInit();
    this.picFlag = true;
    this.picNumber = value;
    this.cameraStart();
  }

  public cameraPic() {
    const video = document.querySelector("video");
    const canvas = <HTMLCanvasElement> document.querySelector("#picture" + this.picNumber);
    canvas.width = 500;
    canvas.height = 500;
    const ctx = canvas.getContext("2d");
    video.pause();
    ctx.drawImage(video, 0, 0, 500, 500, 0, 0, canvas.width, canvas.height);
    if (this.picNumber == 1) this.image1Flag = true;
    if (this.picNumber == 2) this.image2Flag = true;
    if (this.picNumber == 3) this.image3Flag = true;
    if (this.picNumber == 4) this.image4Flag = true;
    if (this.picNumber == 5) this.image5Flag = true;
    if (this.picNumber == 6) this.image6Flag = true;
    this.qrFlagInit();
    this.picNumber = -1;
  }

  public complete() {
    this.errorMessage = '';
    if (this.changeLocation == null || this.changeLocation == '') {
      this.errorMessage = CONST.MESSAGE.PROCESS_INPUT.NONE_LOCATION;
      $('#errorModal').modal();
      return;
    }
    this.postCompleteProcess();
  }

  public back() {
    Common.id = '';
    this.router.navigate([CONST.VIEW.PROCESS_LIST]);
  }

  public edit() {
    this.editInventoryKubunId = this.receive.inventoryKubunId;
    this.editLocation = this.receive.location;
    this.isEdit = true;
  }

  public update() {
    this.errorMessage = '';
    this.processUpdate();
  }

  // Private Function ///////////////////////////////////////////////////////////
  private init() {
    this.changeLocation = '';
    this.image1Flag = false;
    this.image2Flag = false;
    this.image3Flag = false;
    this.image4Flag = false;
    this.image5Flag = false;
    this.image6Flag = false;
    let pImg1 = <HTMLImageElement> document.getElementById('pImg1');
    let pImg2 = <HTMLImageElement> document.getElementById('pImg2');
    let pImg3 = <HTMLImageElement> document.getElementById('pImg3');
    let pImg4 = <HTMLImageElement> document.getElementById('pImg4');
    let pImg5 = <HTMLImageElement> document.getElementById('pImg5');
    let pImg6 = <HTMLImageElement> document.getElementById('pImg6');
    pImg1.src = '';
    pImg2.src = '';
    pImg3.src = '';
    pImg4.src = '';
    pImg5.src = '';
    pImg6.src = '';
    let canv1 = <HTMLCanvasElement> document.querySelector("#picture1");
    let canv2 = <HTMLCanvasElement> document.querySelector("#picture2");
    let canv3 = <HTMLCanvasElement> document.querySelector("#picture3");
    let canv4 = <HTMLCanvasElement> document.querySelector("#picture4");
    let canv5 = <HTMLCanvasElement> document.querySelector("#picture5");
    let canv6 = <HTMLCanvasElement> document.querySelector("#picture6");
    canv1.getContext('2d').clearRect(0, 0, 500, 500);
    canv2.getContext('2d').clearRect(0, 0, 500, 500);
    canv3.getContext('2d').clearRect(0, 0, 500, 500);
    canv4.getContext('2d').clearRect(0, 0, 500, 500);
    canv5.getContext('2d').clearRect(0, 0, 500, 500);
    canv6.getContext('2d').clearRect(0, 0, 500, 500);
  }

  private qrFlagInit() {
    this.qrLocationFlag = false;
    this.picFlag = false;
  }

  private cameraStart() {
    const p = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width:	{min: 500, max: 500},
    		height:	{min: 500, max: 500},
        aspectRatio: 1,
        facingMode: "environment",
        frameRate: { ideal: 5, max: 15 },
      }
    });
    p.then(function(mediaStream) {
      document.querySelector("video").srcObject = mediaStream;
    }).then(() => {
      if (!this.picFlag) this.onQrRead();
    });
  }

  private onQrRead() {
    const video = document.querySelector("video");
    let canv = document.createElement("canvas");
    canv.height = 500;
    canv.width = 500;
    const context = canv.getContext("2d");
    const countList = [];
    this.cameraFunction = setInterval(() => {
      context.drawImage(video, 0, 0, 500, 500);
      const imageData = context.getImageData(0, 0, 500, 500);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        this.codeInsart(code.data);
        video.pause();
        this.qrFlagInit();
        clearInterval(this.cameraFunction);
      }
    }, 500);
  }

  private codeInsart(code: string) {
    if (this.qrLocationFlag) {
      this.changeLocation = code;
    }
  }

  private setImages() {
    if (this.receive.process.imagePath1 != null && this.receive.process.imagePath1 != '') {
      let pImg1 = <HTMLImageElement> document.getElementById('pImg1');
      pImg1.src = this.receive.process.imagePath1;
    }
    if (this.receive.process.imagePath2 != null && this.receive.process.imagePath2 != '') {
      let pImg2 = <HTMLImageElement> document.getElementById('pImg2');
      pImg2.src = this.receive.process.imagePath2;
    }
    if (this.receive.process.imagePath3 != null && this.receive.process.imagePath3 != '') {
      let pImg3 = <HTMLImageElement> document.getElementById('pImg3');
      pImg3.src = this.receive.process.imagePath3;
    }
    if (this.receive.process.imagePath4 != null && this.receive.process.imagePath4 != '') {
      let pImg4 = <HTMLImageElement> document.getElementById('pImg4');
      pImg4.src = this.receive.process.imagePath4;
    }
    if (this.receive.process.imagePath5 != null && this.receive.process.imagePath5 != '') {
      let pImg5 = <HTMLImageElement> document.getElementById('pImg5');
      pImg5.src = this.receive.process.imagePath5;
    }
    if (this.receive.process.imagePath6 != null && this.receive.process.imagePath6 != '') {
      let pImg6 = <HTMLImageElement> document.getElementById('pImg6');
      pImg6.src = this.receive.process.imagePath6;
    }
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.jans = init.jans;
      this.users = init.users;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getDetailProcess() {
    let apiAddress: string = CONST.API.PROCESS.DETAIL;
    let params = {id: this.id};
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      let receive = res as Receive;
      this.receive = receive;
      this.setImages();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
      this.id = '';
    });
  }

  private postCompleteProcess() {
    let apiAddress: string = CONST.API.PROCESS.COMPLETE;
    let params: ApiProcessCreate = new ApiProcessCreate();
    params.id = this.id;
    params.userId = Common.userId;
    params.remarks = this.receive.process.remarks;
    params.location = this.changeLocation;
    params.createdUserId = Common.userId;
    params.updatedUserId = Common.userId;
    if (this.image1Flag) {
      let canv1 = <HTMLCanvasElement> document.querySelector("#picture1");
      params.image1 = Common.imageDecode(canv1);
    }
    if (this.image2Flag) {
      let canv2 = <HTMLCanvasElement> document.querySelector("#picture2");
      params.image2 = Common.imageDecode(canv2);
    }
    if (this.image3Flag) {
      let canv3 = <HTMLCanvasElement> document.querySelector("#picture3");
      params.image3 = Common.imageDecode(canv3);
    }
    if (this.image4Flag) {
      let canv4 = <HTMLCanvasElement> document.querySelector("#picture4");
      params.image4 = Common.imageDecode(canv4);
    }
    if (this.image5Flag) {
      let canv5 = <HTMLCanvasElement> document.querySelector("#picture5");
      params.image5 = Common.imageDecode(canv5);
    }
    if (this.image6Flag) {
      let canv6 = <HTMLCanvasElement> document.querySelector("#picture6");
      params.image6 = Common.imageDecode(canv6);
    }
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      this.init();
      $('#successModal').modal();
      this.getDetailProcess();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private processUpdate() {
    let apiAddress: string = CONST.API.PROCESS.UPDATE;
    let params: ApiProcessUpdate = new ApiProcessUpdate();
    params.id = this.receive.id;
    params.inventoryKubunId = this.editInventoryKubunId;
    params.processRemarks = this.receive.process.remarks;
    params.location = this.editLocation;
    params.createdUserId = Common.userId;
    params.updatedUserId = Common.userId;
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      this.isEdit = false;
      this.editInventoryKubunId = -1;
      this.editLocation = '';
      $('#successModal').modal();
      this.getDetailProcess();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
