export class User {
  id?: string;
  baseId?: number;
  name?: string;
  isAdmin?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  isDeleted?: boolean;
  baseName?: string;

  constructor() {
    this.id = '';
    this.baseId = null;
    this.name = '';
    this.isAdmin = false;
  }
}
