import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { Init } from './../../_interfaces/init.model';
import { Receive } from './../../_interfaces/receive.model';
import { ApiSearch } from './../../_interfaces/api-search.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';

@Component({
  selector: 'app-receive-list',
  templateUrl: './receive-list.component.html',
  styleUrls: ['./receive-list.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ReceiveListComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public receives: Receive[] = [];
  public apiSearch: ApiSearch = new ApiSearch();

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorMessage: string = '';
  public isDisp: boolean = false;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
      if (this.isMobile && !Common.init) {
        this.menuState = CONST.MENU.IN;
        Common.init = true;
      }
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public searchDisp() {
    this.isDisp = true;
  }

  public searchNoDisp() {
    this.isDisp = false;
  }

  public arrivalClear() {
    this.apiSearch.arrivalStart = null;
    this.apiSearch.arrivalEnd = null;
  }

  public categoryIdClear() {
    this.apiSearch.categoryId = null;
  }

  public nameClear() {
    this.apiSearch.name = null;
  }
  public inchIdClear() {
    this.apiSearch.inchId = null;
  }

  public kubun1IdClear() {
    this.apiSearch.kubun1Id = null;
  }

  public kubun2IdClear() {
    this.apiSearch.kubun2Id = null;
  }

  public inventoryKubunIdClear() {
    this.apiSearch.inventoryKubunId = null;
  }

  public riKubunIdClear() {
    this.apiSearch.riKubunId = null;
  }

  public search() {
    this.isDisp = false;
    this.receives = [];
    this.errorMessage = '';
    this.getListReceive();
  }

  public csvOutput() {
    this.errorMessage = '';
    this.getCsvOutput();
  }

  public csvtape() {
    this.errorMessage = '';
    this.getCsvtape();
  }

  // Private Function ///////////////////////////////////////////////////////////
  private setParams(apiSearch: ApiSearch) {
    return apiSearch;
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.jans = init.jans;
      this.users = init.users;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getListReceive() {
    let apiAddress: string = CONST.API.RECEIVE.LIST;
    let params = this.setParams(this.apiSearch);
    params.baseId = Common.baseId;
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      let receives = res as Receive[];
      this.receives = receives;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getCsvOutput() {
    let apiAddress: string = CONST.API.RECEIVE.CSV_LIST;
    let params = this.setParams(this.apiSearch);
    this.repository.postFile(apiAddress, params).subscribe(res => {
      let blob = new Blob([(<any>res)], {type: 'text/csv'});
      let dt = new Date();
      let y = dt.getFullYear();
      let m = ("00" + (dt.getMonth()+1)).slice(-2);
      let d = ("00" + dt.getDate()).slice(-2);
      let h = ("00" + dt.getHours()).slice(-2);
      let mi = ("00" + dt.getMinutes()).slice(-2);
      let s = ("00" + dt.getSeconds()).slice(-2);
      let now = y + m + d + h + mi + s;

      let csvFileName = `入荷一覧_${now}.csv`;
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, csvFileName);
      } else {
        let link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', csvFileName);
        document.body.appendChild(link);
        link.click()
        link.remove();
      }
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getCsvtape() {
    let apiAddress: string = CONST.API.RECEIVE.CSV_TAPE;
    let params = this.setParams(this.apiSearch);
    this.repository.postFile(apiAddress, params).subscribe(res => {
      let blob = new Blob([(<any>res)], {type: 'text/csv'});
      let dt = new Date();
      let y = dt.getFullYear();
      let m = ("00" + (dt.getMonth()+1)).slice(-2);
      let d = ("00" + dt.getDate()).slice(-2);
      let h = ("00" + dt.getHours()).slice(-2);
      let mi = ("00" + dt.getMinutes()).slice(-2);
      let s = ("00" + dt.getSeconds()).slice(-2);
      let now = y + m + d + h + mi + s;

      let csvFileName = `テプラ用_${now}.csv`;
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, csvFileName);
      } else {
        let link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', csvFileName);
        document.body.appendChild(link);
        link.click()
        link.remove();
      }
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
