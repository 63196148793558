export class ApiSearch {
  id?: string;
  baseId?: number;
  location?: string;
  arrivalStart?: Date;
  arrivalEnd?: Date;
  processOrderStart?: Date;
  processOrderEnd?: Date;
  processCompleteStart?: Date;
  processCompleteEnd?: Date;
  shipmentOrderStart?: Date;
  shipmentOrderEnd?: Date;
  shipmentStart?: Date;
  shipmentEnd?: Date;
  categoryId?: number;
  name?: string;
  inchId?: number;
  kubun1Id?: number;
  kubun2Id?: number;
  inventoryKubunId?: number;
  riKubunId?: number;
  kubunId?: number;
  fecesId?: number;
  toName?: string;
  displayZeroFlag: boolean;

  constructor() {
    this.id = null;
    this.baseId = null;
    this.location = null;
    this.arrivalStart = null;
    this.arrivalEnd = null;
    this.processOrderStart = null;
    this.processOrderEnd = null;
    this.processCompleteStart = null;
    this.processCompleteEnd = null;
    this.shipmentOrderStart = null;
    this.shipmentOrderEnd = null;
    this.shipmentStart = null;
    this.shipmentEnd = null;
    this.categoryId = null;
    this.name = null;
    this.inchId = null;
    this.kubun1Id = null;
    this.kubun2Id = null;
    this.inventoryKubunId = null;
    this.riKubunId = null;
    this.kubunId = null;
    this.fecesId = null;
    this.toName = null;
    this.displayZeroFlag = false;
  }
}
