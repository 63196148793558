export class ApiProcessCreate {
  id?: string;
  remarks?: string;
  userId?: string;
  orderAt?: Date;
  completeAt?: Date;
  imagePath1?: string;
  imagePath2?: string;
  imagePath3?: string;
  imagePath4?: string;
  imagePath5?: string;
  imagePath6?: string;
  image1?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  image5?: string;
  image6?: string;
  location?: string;
  createdUserId?: string;
  updatedUserId?: string;

  constructor() {
    this.id = null;
    this.remarks = null;
    this.userId = null;
    this.orderAt = null;
    this.completeAt = null;
    this.imagePath1 = null;
    this.imagePath2 = null;
    this.imagePath3 = null;
    this.imagePath4 = null;
    this.imagePath5 = null;
    this.imagePath6 = null;
    this.image1 = null;
    this.image2 = null;
    this.image3 = null;
    this.image4 = null;
    this.image5 = null;
    this.image6 = null;
    this.location = null;
    this.createdUserId = null;
    this.updatedUserId = null;
  }
}
