import { Component, OnInit, Input, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, ActivatedRoute} from '@angular/router';
import is from 'is_js';

import { RepositoryService } from './../../../shared/services/repository.service';
import { ErrorHandlerService } from './../../../shared/services/error-handler.service';

import { CONST } from './../../../shared/const';
import { Common } from './../../../shared/common';

import { Init } from './../../../_interfaces/init.model';
import { User } from './../../../_interfaces/user.model';
import { Company } from './../../../_interfaces/company.model';
import { Jan } from './../../../_interfaces/jan.model';
import { Value } from './../../../_interfaces/value.model';

@Component({
  selector: 'app-jan-maintenance',
  templateUrl: './jan-maintenance.component.html',
  styleUrls: ['./jan-maintenance.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class JanMaintenanceComponent implements OnInit {

  // public Data ////////////////////////////////
  public jan: Jan = new Jan();
  public backData: Jan = new Jan();

  public idLogMessage: string = '';
  public companyIdLogMessage: string = '';
  public codeLogMessage: string = '';
  public nameLogMessage: string = '';

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public users: User[] = [];

  // Etc Data //////////////////////////////////////
  public errorMessage: string = '';
  public modeMessage: string = '';

  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public mode: string = '';
  public isEditMode: boolean = false;
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  // Init Action //////////////////////////////////////////////////////////
  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
      this.route.params.subscribe(params => {
        this.mode = params['mode'];
        switch (this.mode) {
          case CONST.MODE.CREATE:
            this.modeMessage = '[新規入力]';
            this.isEditMode = false;
            break;
          case CONST.MODE.EDIT:
            this.modeMessage = '[入力修正]';
            this.isEditMode = true;
            this.getDetail(params['id']);
            break;
        }
      });
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }
  
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action //////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public moveListView() {
    this.router.navigate([CONST.VIEW.JAN_LIST]);
  }

  public inputIdClear() {
    this.jan.id = null;
  }

  public inputCodeClear() {
    this.jan.code = null;
  }

  public inputNameClear() {
    this.jan.name = null;
  }

  public save() {
    this.idLogMessage = '';
    this.companyIdLogMessage = '';
    this.codeLogMessage = '';
    this.nameLogMessage = '';

    if (!this.jan.id) {
      this.idLogMessage = CONST.MESSAGE.JAN_MAINTENANCE.NONE_ID;
      return;
    } else if (!this.jan.companyId) {
      this.companyIdLogMessage = CONST.MESSAGE.JAN_MAINTENANCE.NONE_COMPANY_ID;
      return;
    } else if (!this.jan.code) {
      this.codeLogMessage = CONST.MESSAGE.JAN_MAINTENANCE.NONE_CODE;
      return;
    } else if (!this.jan.name) {
      this.nameLogMessage = CONST.MESSAGE.JAN_MAINTENANCE.NONE_NAME;
      return;
    }

    switch (this.mode) {
      case CONST.MODE.CREATE:
        this.create();
        break;
      case CONST.MODE.EDIT:
        this.update();
        break;
    }
  }

  public confirm() {
    if (this.mode == CONST.MODE.CREATE && (this.jan.id || this.jan.name || this.jan.code || this.jan.companyId)) {
      $('#actionModal').modal();
    } else if(this.mode == CONST.MODE.CREATE) {
      this.moveListView();
    } else {
      if (this.backData.name != this.jan.name || this.backData.code != this.jan.code
      || this.backData.companyId != this.jan.companyId) {
        $('#actionModal').modal();
      } else {
        this.moveListView();
      }
    }
  }

  // Private Action ///////////////////////////////////////////////////////
  // Web Api //////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.users = init.users;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  public getDetail(id: string) {
    let apiAddress: string = CONST.API.JAN.DETAIL;
    let res = this.repository.post(apiAddress, {id: id}).subscribe(res => {
      let jan = res as Jan;
      this.jan = jan;
      this.backData = jan;
    }, error => {
      this.moveListView();
    });
  }

  public create() {
    let apiAddress: string = CONST.API.JAN.CREATE;
    this.repository.post(apiAddress, this.jan).subscribe(res => {
      this.moveListView();
    }, error => {
      if (error.status == 409) {
        this.errorMessage = CONST.MESSAGE.JAN_MAINTENANCE.DUPLICATE;
      } else {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      };
    });
  }

  public update() {
    let apiAddress: string = CONST.API.JAN.UPDATE;
    this.repository.post(apiAddress, this.jan).subscribe(res => {
      this.moveListView();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
