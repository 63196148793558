export class Company {
  id?: string;
  name?: string;
  isPurchase?: boolean;
  isMaker?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  isDeleted?: boolean;

  constructor() {
    this.id = '';
    this.name = '';
    this.isPurchase = false;
    this.isMaker = false;
  }
}
