export class Process {
  id?: string;
  baseId?: number;
  remarks?: string;
  userId?: string;
  orderAt?: Date;
  completeAt?: Date;
  imagePath1?: string;
  imagePath2?: string;
  imagePath3?: string;
  imagePath4?: string;
  imagePath5?: string;
  imagePath6?: string;
  createdUserId?: string;
  createdAt?: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  isDeleted?: boolean;

  createdUserName?: string;
  updatedUserName?: string;
  userName?: string;

  constructor() {
    this.id = null;
    this.baseId = null;
    this.remarks = null;
    this.userId = null;
    this.orderAt = null;
    this.completeAt = null;
    this.imagePath1 = null;
    this.imagePath2 = null;
    this.imagePath3 = null;
    this.imagePath4 = null;
    this.imagePath5 = null;
    this.imagePath6 = null;
    this.createdUserId = null;
    this.createdAt = null;
    this.updatedUserId = null;
    this.updatedAt = null;
    this.isDeleted = null;
    this.createdUserName = null;
    this.updatedUserName = null;
    this.userName = null;
  }
}
