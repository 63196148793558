import { Routes} from '@angular/router';

import { LoginComponent } from './main/login/login.component';
import { ArriveMoveComponent } from './main/arrive-move/arrive-move.component';
import { ArriveQrComponent } from './main/arrive-qr/arrive-qr.component';
import { ProcessListComponent } from './main/process-list/process-list.component';
import { ProcessInputComponent } from './main/process-input/process-input.component';
import { ReceiveJanComponent } from './main/receive-jan/receive-jan.component';
import { ReceiveListComponent } from './main/receive-list/receive-list.component';
import { ReceiveQrComponent } from './main/receive-qr/receive-qr.component';
import { ShipmentListComponent } from './main/shipment-list/shipment-list.component';
import { ShipmentInputComponent } from './main/shipment-input/shipment-input.component';
import { ShipmentDetailComponent } from './main/shipment-detail/shipment-detail.component';
import { StockListComponent } from './main/stock-list/stock-list.component';
import { StockDetailComponent } from './main/stock-detail/stock-detail.component';

import { UserListComponent } from './maintenance/users/user-list/user-list.component';
import { UserMaintenanceComponent } from './maintenance/users/user-maintenance/user-maintenance.component';
import { CompanyListComponent } from './maintenance/companies/company-list/company-list.component';
import { CompanyMaintenanceComponent } from './maintenance/companies/company-maintenance/company-maintenance.component';
import { JanListComponent } from './maintenance/jans/jan-list/jan-list.component';
import { JanMaintenanceComponent } from './maintenance/jans/jan-maintenance/jan-maintenance.component';

import { NotFoundComponent } from './shared/error-pages/not-found/not-found.component';
import { InternalServerComponent } from './shared/error-pages/internal-server/internal-server.component';

export const ROUTES: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'arrive-move', component: ArriveMoveComponent },
  { path: 'arrive-qr', component: ArriveQrComponent },
  { path: 'process-list', component: ProcessListComponent },
  { path: 'process-input', component: ProcessInputComponent },
  { path: 'receive-jan', component: ReceiveJanComponent },
  { path: 'receive-list', component: ReceiveListComponent },
  { path: 'receive-qr', component: ReceiveQrComponent },
  { path: 'shipment-list', component: ShipmentListComponent },
  { path: 'shipment-input', component: ShipmentInputComponent },
  { path: 'shipment-detail', component: ShipmentDetailComponent },
  { path: 'shipment-detail/:id/:sub-number/:user-id', component: ShipmentDetailComponent},
  { path: 'stock-list', component: StockListComponent },
  { path: 'stock-detail', component: StockDetailComponent },
  { path: 'user-list', component: UserListComponent},
  { path: 'user-maintenance/:mode/:id', component: UserMaintenanceComponent},
  { path: 'company-list', component: CompanyListComponent},
  { path: 'company-maintenance/:mode/:id', component: CompanyMaintenanceComponent},
  { path: 'jan-list', component: JanListComponent},
  { path: 'jan-maintenance/:mode/:id', component: JanMaintenanceComponent},

  { path: '404', component : NotFoundComponent},
  { path: '500', component: InternalServerComponent},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/404', pathMatch: 'full'}
];
