import { Component, OnInit, Input, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate} from '@angular/animations';
import is from 'is_js';

import { RepositoryService } from './../../../shared/services/repository.service';
import { ErrorHandlerService } from './../../../shared/services/error-handler.service';

import { Router } from '@angular/router';
import { CONST } from './../../../shared/const';
import { Common } from './../../../shared/common';

import { Init } from './../../../_interfaces/init.model';
import { User } from './../../../_interfaces/user.model';
import { Company } from './../../../_interfaces/company.model';
import { Jan } from './../../../_interfaces/jan.model';
import { Value } from './../../../_interfaces/value.model';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class CompanyListComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public companies: Company[] = [];
  public companyId: String = '';

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data //////////////////////////////////////
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorTitle = '';
  public errorMessage = '';
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }
  
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public selectRow(event: any) {
    this.companyId = event.selected[0].id;
  }

  public confirm() {
    this.errorTitle = '';
    this.errorMessage = '';
    if (this.companyId == '') {
      this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NONE_ROW;
      this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NONE_ROW;
      $('#errorModal').modal();
      return;
    } else {
      $('#actionModal').modal();
    };
  }

  public changePurchase() {
    this.postChengPurchase();
  }

  public changeMaker() {
    this.postChengMaker();
  }

  public companyDelete() {
    this.postCompanyDelete();
  }

  public getRowClass(row) {
    return {'delete-color': row.isDeleted};
  }

  public cancel() {
  }

  public moveInputView(mode: string) {
    switch(mode) {
      case CONST.MODE.CREATE:
        this.companyId = '';
      break;
      case CONST.MODE.EDIT:
        if (this.companyId == '') {
          this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NONE_ROW;
          this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NONE_ROW;
          $('#errorModal').modal();
          return;
        }
      break;
    }
    this.router.navigate([CONST.VIEW.COMPANY_MAINTENANCE, mode, this.companyId]);
  }

  // Private Function ///////////////////////////////////////////////////////////

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.users = init.users;
      this.jans = init.jans;
      this.getCompanyList();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getCompanyList() {
    let apiAddress: string = CONST.API.COMPANY.LIST;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let companies = res as Company[];
      this.companies = companies;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
  private postChengPurchase() {
    let apiAddress: string = CONST.API.COMPANY.PURCHASE;
    this.repository.post(apiAddress, {id: this.companyId}).subscribe(res => {
      this.getCompanyList();
      this.companyId = '';
    }, error => {
      this.errorHandler.handleError(error);
    });
  }

  private postChengMaker() {
    let apiAddress: string = CONST.API.COMPANY.MAKER;
    this.repository.post(apiAddress, {id: this.companyId}).subscribe(res => {
      this.getCompanyList();
      this.companyId = '';
    }, error => {
      this.errorHandler.handleError(error);
    });
  }

  private postCompanyDelete() {
    let apiAddress: string = CONST.API.COMPANY.DELETE;
    this.repository.post(apiAddress, {id: this.companyId}).subscribe(res => {
      this.getCompanyList();
      this.companyId = '';
    }, error => {
      this.errorHandler.handleError(error);
    });
  }
}
