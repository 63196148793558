import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';
import quagga from 'quagga';
import jsQR from "jsqr";
import Quagga2 from '@ericblade/quagga2';

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { Init } from './../../_interfaces/init.model';
import { Receive } from './../../_interfaces/receive.model';
import { ApiReceive } from './../../_interfaces/api-receive.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';
import { JanCode } from './../../_interfaces/jan-code.model';

@Component({
  selector: 'app-receive-jan',
  templateUrl: './receive-jan.component.html',
  styleUrls: ['./receive-jan.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ReceiveJanComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public janCode: string = '';
  public receive: Receive = new Receive();
  public arrivalAtStr: string = '';

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorMessage: string = '';
  public cameraFunction;
  public qrIdFlag: boolean = false;
  public qrLocationFlag: boolean = false;
  public stream;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public janClear() {
    this.janCode = null;
    this.receive.name = null;
  }
  public categoryIdClear() {
    this.receive.categoryId = null;
  }

  public quantityClear() {
    this.receive.quantity = null;
  }

  public inchIdClear() {
    this.receive.inchId = null;
  }

  public purchaseIdClear() {
    this.receive.purchaseId = null;
  }

  public arrivalAtClear() {
    this.receive.arrivalAt = null;
  }

  public kubun1IdClear() {
    this.receive.kubun1Id = null;
  }

  public kubun2IdClear() {
    this.receive.kubun2Id = null;
  }

  public inventoryKubunIdClear() {
    this.receive.inventoryKubunId = null;
  }

  public riKubunIdClear() {
    this.receive.riKubunId = null;
  }

  public locationClear() {
    this.receive.location = null;
  }

  public arrivalRemarksClear() {
    this.receive.arrivalRemarks = null;
  }

  public onClickIdQr() {
    this.qrFlagInit();
    this.qrIdFlag = true;
    this.cameraStart();
  }

  public onClickLocationQr() {
    this.qrFlagInit();
    this.qrLocationFlag = true;
    this.cameraStart();
  }

  public cameraCancel() {
    this.qrFlagInit();
    clearInterval(this.cameraFunction);
  }

  public save() {
    if (this.inputCheck()) {
      this.postSaveReceive();
    }
  }

  // Private Function ///////////////////////////////////////////////////////////
  private qrFlagInit() {
    this.qrIdFlag = false;
    this.qrLocationFlag = false;
  }

  private cameraStart() {
    // if (this.stream) {
    //   this.stream.getTracks().forEach(track => track.stop());
    //   this.stream = null;
    // }
    const p = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width:	500,
    		height:	500,
        facingMode: "environment",
        frameRate: { ideal: 5, max: 15 },
      }
    }).then((mediaStream) => {
      // this.stream = mediaStream;
      document.querySelector("video").srcObject = mediaStream;
    }).then(() => {
      this.onQrRead();
    });
  }

  private onQrRead() {
    let box = {x: 100, y: 100, h: 300, w: 300};
    const video = document.querySelector("video");
    let canv = document.querySelector("canvas");
    canv.height = 500;
    canv.width = 500;
    const context = canv.getContext("2d");
    const countList = [];

    this.cameraFunction = setInterval(() => {
      if (this.qrIdFlag) {
        context.drawImage(video, 0, 0, 500, 500);
        context.beginPath();
        context.strokeStyle = 'red';
        context.lineWidth = 2;
        context.rect(box.x, box.y, box.w, box.h);
        context.stroke();
        this.cameraPic();
        // this.readBarCode(canv.toDataURL('image/png', 1));
        // Quagga2.decodeSingle({
        //   decoder: {
        //     readers: ["ean_reader"],
        //     multiple: false,
        //   },
        //   locator: {
        //     patchSize: "large",
        //     halfSample: false
        //   },
        //   locate: false,
        //   src: canv.toDataURL('image/png', 1),
        // }, result => {
        //   if (result && result.codeResult) {
        //     let flag = true;
        //     for (let i = 0; i < countList.length; i++) {
        //       if (countList[i].code == result.codeResult.code) {
        //         countList[i].count++;
        //         if (2 <= countList[i].count) {
        //           this.codeInsart(result.codeResult.code);
        //           video.pause();
        //           this.qrFlagInit();
        //           clearInterval(this.cameraFunction);
        //         }
        //         flag = false;
        //         break;
        //       }
        //     }
        //     if (flag) {
        //       countList.push({code: result.codeResult.code, count: 1});
        //     }
        //   }
        // });
      } else if (this.qrLocationFlag) {
        context.drawImage(video, 0, 0, 500, 500);
        const imageData = context.getImageData(0, 0, 500, 500);

        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          this.codeInsart(code.data);
          video.pause();
          this.qrFlagInit();
          clearInterval(this.cameraFunction);
        }
      }
    }, 500);
  }

  private cameraPic() {
    const video = document.querySelector("video");
    let box = {x: 100, y: 100, h: 300, w: 300};

    let canv = document.querySelector("canvas");
    const canvas = <HTMLCanvasElement> document.createElement("canvas");
    canvas.width = box.w;
    canvas.height = box.h;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(canv, box.x, box.y, box.w, box.h, 0, 0, box.w, box.h);
    let janCode = Common.imageDecode(canvas);
    let apiAddress: string = CONST.API.RECEIVE.CHECK;
    let res = this.repository.post(apiAddress, {janCode: janCode}).subscribe(res => {
      if (res != null) {
        let janCode = res as JanCode;
        if (janCode.code != '') {
          let values = janCode.code.split(":");
          if (values.length == 2 && values[0] == 'EAN-13') {
            this.codeInsart(values[1].substring(0,12));
            video.pause();
            this.qrFlagInit();
            clearInterval(this.cameraFunction);
          }
        }
      }
    }, error => {
      video.pause();
      this.qrFlagInit();
      clearInterval(this.cameraFunction);
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private codeInsart(code: string) {
    if (this.qrIdFlag) {
      this.janCode = '';
      for (let i = 0; i <= this.jans.length; i++) {
        if (this.jans[i].code == code) {
          this.janCode = code;
          this.receive.name = this.jans[i].name;
          break;
        }
      }
      if (this.janCode == '') {
        this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NO_MATCH_CODE;
        $('#errorModal').modal();
      }
    } else if (this.qrLocationFlag) {
      this.receive.location = code;
    }
  }

  private inputCheck(): boolean {
    this.errorMessage = '';
    if (!Common.isNumberValue(this.receive.categoryId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_CATEGORY;
    } else if (!Common.isStringValue(this.receive.name)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_NAME;
    } else if (!Common.isNumberValue(this.receive.inchId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_INCH;
    } else if (!Common.isNumberValue(this.receive.quantity)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_QUANTITY;
    } else if (this.receive.quantity <= 0) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.ERROR_QUANTITY;
    } else if (!Common.isNumberValue(this.receive.kubun1Id)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_KUBUN_FIRST;
    } else if (!Common.isNumberValue(this.receive.kubun2Id)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_KUBUN_SECOND;
    } else if (!Common.isNumberValue(this.receive.inventoryKubunId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_INVENTORY_KUBUN;
    } else if (!Common.isNumberValue(this.receive.riKubunId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_RI_KUBUN;
    } else if (!Common.isStringValue(this.receive.location)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_JAN.NONE_LOCATION;
    }
    if (this.errorMessage != '') $('#errorModal').modal();
    return this.errorMessage == '';
  }

  private setParams(): ApiReceive {
    let purchaseId = this.receive.purchaseId;
    for (let i = 0; i < this.companies.length; i++) {
      if (this.companies[i].name == this.receive.purchaseName) {
        purchaseId = this.companies[i].id;
      }
    }

    let apiReceive: ApiReceive = new ApiReceive();
    apiReceive.id = '';
    apiReceive.categoryId = this.receive.categoryId;
    apiReceive.inchId = this.receive.inchId;
    apiReceive.name = this.receive.name;
    apiReceive.baseId = Common.baseId;
    apiReceive.janId = this.receive.janId;
    apiReceive.arrivalAt = new Date(this.arrivalAtStr);
    apiReceive.quantity = this.receive.quantity;
    apiReceive.purchaseId = purchaseId;
    apiReceive.purchaseName = this.receive.purchaseName;
    apiReceive.kubun1Id = this.receive.kubun1Id;
    apiReceive.kubun2Id = this.receive.kubun2Id;
    apiReceive.inventoryKubunId = this.receive.inventoryKubunId;
    apiReceive.riKubunId = this.receive.riKubunId;
    apiReceive.location = this.receive.location;
    apiReceive.arrivalRemarks = this.receive.arrivalRemarks;
    apiReceive.createdUserId = Common.userId;
    apiReceive.updatedUserId = Common.userId;
    return apiReceive;
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies.filter(company => {return company.isPurchase;});
      this.jans = init.jans;
      this.users = init.users;
      let date = new Date();
      this.arrivalAtStr = Common.getStringFromDate(date, 'YYYY-MM-DD');
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private postSaveReceive() {
    let apiAddress: string = CONST.API.RECEIVE.CREATE;
    let params: ApiReceive = this.setParams();
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      this.janCode = null;
      this.receive = new Receive();
      let date = new Date();
      this.arrivalAtStr = Common.getStringFromDate(date, 'YYYY-MM-DD');
      $('#successModal').modal();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
