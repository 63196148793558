export class ApiReceive {
  id?: string;
  categoryId?: number;
  inchId?: number;
  name?: string;
  baseId?: number;
  janId?: string;
  arrivalAt?: Date;
  quantity?: number;
  purchaseId?: string;
  purchaseName?: string;
  kubun1Id?: number;
  kubun2Id?: number;
  inventoryKubunId?: number;
  riKubunId?: number;
  location?: string;
  arrivalRemarks?: string;
  createdUserId?: string;
  updatedUserId?: string;

  constructor() {
    this.id = '';
    this.categoryId = null;
    this.inchId = null;
    this.name = '';
    this.baseId = null;
    this.janId = '';
    this.arrivalAt = null;
    this.quantity = null;
    this.purchaseId = '';
    this.purchaseName = '';
    this.kubun1Id = null;
    this.kubun2Id = null;
    this.inventoryKubunId = null;
    this.riKubunId = null;
    this.location = '';
    this.arrivalRemarks = '';
    this.createdUserId = '';
    this.updatedUserId = '';
  }
}
