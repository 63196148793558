import { Component, OnInit, Input, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate} from '@angular/animations';
import is from 'is_js';

import { RepositoryService } from './../../../shared/services/repository.service';
import { ErrorHandlerService } from './../../../shared/services/error-handler.service';

import { Router } from '@angular/router';
import { CONST } from './../../../shared/const';
import { Common } from './../../../shared/common';

import { Init } from './../../../_interfaces/init.model';
import { User } from './../../../_interfaces/user.model';
import { Company } from './../../../_interfaces/company.model';
import { Jan } from './../../../_interfaces/jan.model';
import { Value } from './../../../_interfaces/value.model';

@Component({
  selector: 'app-jan-list',
  templateUrl: './jan-list.component.html',
  styleUrls: ['./jan-list.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class JanListComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public jans: Jan[] = [];

  public janId: string = '';
  public companyId: string = '';
  public code: string = '';
  public name: string = '';
  public inportCompanyId: string = '';

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public users: User[] = [];

  // Master Data //////////////////////////////////////
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorTitle = '';
  public errorMessage = '';
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }
  
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public inputCodeClear() {
    this.code = null;
  }

  public inputNameClear() {
    this.name = null;
  }

  public selectRow(event: any) {
    this.janId = event.selected[0].id;
  }

  public confirm() {
    this.errorTitle = '';
    this.errorMessage = '';
    if (this.janId == '') {
      this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NONE_ROW;
      this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NONE_ROW;
      $('#errorModal').modal();
      return;
    } else {
      $('#actionModal').modal();
    };
  }

  public janDelete() {
    this.postJanDelete();
  }

  public getRowClass(row) {
    return {'delete-color': row.isDeleted};
  }

  public cancel() {
  }

  public search() {
    this.getJanList();
  }

  public import() {
    let input = document.getElementById('inputFile');
    input.click();
  }

  public async importFile() {
    let input = document.getElementById('inputFile') as HTMLInputElement;
    try {
      if (input.files.length > 0) {
        let items = await Common.csvImport(['code', 'name'], input.files[0]);
        for (let i = 0; i < items.length; i++) {
          if (!items[i]['code'] || !items[i]['name']) {
            this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
            this.errorMessage = CONST.MESSAGE.JAN_LIST.NO_TWO_COL;
            $('#errorModal').modal();
            return;
          }
        }

        if (items.length > 0) {
          let companyName = '';
          for (let i = 0; i < this.companies.length; i++) {
            if (this.companies[i].id == this.inportCompanyId) {
              companyName = this.companies[i].name;
              break;
            }
          }
          for (let i = 0; i < items.length; i++) {
            items[i]['companyId'] = this.inportCompanyId;
            items[i]['name'] = companyName + ' ' + items[i]['name'];
          }
          this.postCsvJans(items);
        } else {
          this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
          this.errorMessage = CONST.MESSAGE.JAN_LIST.NO_ROW;
          $('#errorModal').modal();
        }
      }
    } catch (e) {
      this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
      this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NO_CSV_IMPORT;
      $('#errorModal').modal();
    }
    input.value = '';
  }

  public moveInputView(mode: string) {
    switch(mode) {
      case CONST.MODE.CREATE:
        this.janId = '';
      break;
      case CONST.MODE.EDIT:
        if (this.janId == '') {
          this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NONE_ROW;
          this.errorMessage = CONST.MESSAGE.MODAL_MESSAGE.NONE_ROW;
          $('#errorModal').modal();
          return;
        }
      break;
    }
    this.router.navigate([CONST.VIEW.JAN_MAINTENANCE, mode, this.janId]);
  }

  // Private Function ///////////////////////////////////////////////////////////

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies.filter(company => {return company.isMaker;});
      this.users = init.users;
      this.getJanList();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getJanList() {
    let apiAddress: string = CONST.API.JAN.LIST;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let jans = res as Jan[];
      this.jans = jans;
      this.jans = this.jans.filter(jan => {
        if (!this.companyId) return true;
        return (jan.companyId == this.companyId);
      });
      this.jans = this.jans.filter(jan => {
        if (!this.code) return true;
        return (!jan.code.indexOf(this.code));
      });
      this.jans = this.jans.filter(jan => {
        if (!this.name) return true;
        return (~jan.name.indexOf(this.name));
      });
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private postJanDelete() {
    let apiAddress: string = CONST.API.JAN.DELETE;
    this.repository.post(apiAddress, {id: this.janId}).subscribe(res => {
      this.getJanList();
      this.janId = '';
    }, error => {
      this.errorHandler.handleError(error);
    });
  }

  private postCsvJans(items) {
    let apiAddress: string = CONST.API.JAN.CREATES;
    this.repository.post(apiAddress, items).subscribe(res => {
      $('#successModal').modal();
      this.getJanList();
    }, error => {
      if (error.status == 410) {
        this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
        error.error = CONST.MESSAGE.JAN_LIST.ERROR_ROW + '\r\n' + String(error.error);
        this.errorHandler.handleError(error);
        this.errorMessage = error.error;
      } else {
        this.errorHandler.handleError(error);
        this.errorTitle = CONST.MESSAGE.MODAL_TITLE.NO_CSV_IMPORT;
        this.errorMessage = error.error;
      }
    });
  }
}
