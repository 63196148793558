import { Stock } from '../_interfaces/stock.model';
import { Arrive } from '../_interfaces/arrive.model';
import { Process } from '../_interfaces/process.model';
import { Shipment } from '../_interfaces/shipment.model';

export class Receive {
  id?: string;
  categoryId?: number;
  inchId?: number;
  name?: string;
  baseId?: number;
  janId?: string;
  arrivalAt?: Date;
  quantity?: number;
  purchaseId?: string;
  purchaseName?: string;
  kubun1Id?: number;
  kubun2Id?: number;
  inventoryKubunId?: number;
  riKubunId?: number;
  location?: string;
  arrivalRemarks?: string;
  createdUserId?: string;
  createdAt?: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  isDeleted?: boolean;

  categoryName?: string;
  inchName?: string;
  baseName?: string;
  kubun1Name?: string;
  kubun2Name?: string;
  inventoryKubunName?: string;
  riKubunName?: string;
  createdUserName?: string;
  updatedUserName?: string;
  stock?: Stock;
  arrive?: Arrive;
  process?: Process;
  shipment?: Shipment;

  selectedColor?: string;

  constructor() {
    this.id = null;
    this.categoryId = null;
    this.inchId = null;
    this.name = null;
    this.baseId = null;
    this.janId = null;
    this.arrivalAt = null;
    this.quantity = null;
    this.purchaseId = null;
    this.purchaseName = null;
    this.kubun1Id = null;
    this.kubun2Id = null;
    this.inventoryKubunId = null;
    this.riKubunId = null;
    this.location = null;
    this.arrivalRemarks = null;
    this.createdUserId = null;
    this.createdAt = null;
    this.updatedUserId = null;
    this.updatedAt = null;
    this.isDeleted = null;
    this.categoryName = null;
    this.inchName = null;
    this.baseName = null;
    this.kubun1Name = null;
    this.kubun2Name = null;
    this.inventoryKubunName = null;
    this.riKubunName = null;
    this.createdUserName = null;
    this.updatedUserName = null;
    this.stock = new Stock();
    this.arrive = new Arrive();
    this.process = new Process();
    this.shipment = new Shipment();
    this.selectedColor = null;
  }
}
