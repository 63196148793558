import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';
import jsQR from "jsqr";

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { ApiArrive } from './../../_interfaces/api-arrive.model';
import { Receive } from './../../_interfaces/receive.model';
import { Init } from './../../_interfaces/init.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';

@Component({
  selector: 'app-arrive-move',
  templateUrl: './arrive-move.component.html',
  styleUrls: ['./arrive-move.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ArriveMoveComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public id: string = '';
  public changeLocation: string = '';
  public changeQuantity: number = null;
  public receive: Receive = new Receive();
  public image1Flag: boolean = false;
  public image2Flag: boolean = false;
  public image3Flag: boolean = false;
  public image4Flag: boolean = false;

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorMessage: string = '';
  public cameraFunction;
  public qrIdFlag: boolean = false;
  public qrLocationFlag: boolean = false;
  public picFlag: boolean = false;
  public picNumber: number = -1;
  public stream;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    let canvBack1 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack2 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack3 = <HTMLCanvasElement> document.createElement("canvas");
    let canvBack4 = <HTMLCanvasElement> document.createElement("canvas");
    const contextBack1 = canvBack1.getContext("2d");
    const contextBack2 = canvBack2.getContext("2d");
    const contextBack3 = canvBack3.getContext("2d");
    const contextBack4 = canvBack4.getContext("2d");
    if (this.image1Flag) {
      let canv1 = <HTMLCanvasElement> document.querySelector("#picture1");
      canvBack1.width = canv1.width;
      canvBack1.height = canv1.height;
      contextBack1.drawImage(canv1, 0, 0, canv1.width, canv1.height);
    }
    if (this.image2Flag) {
      let canv2 = <HTMLCanvasElement> document.querySelector("#picture2");
      canvBack2.width = canv2.width;
      canvBack2.height = canv2.height;
      contextBack2.drawImage(canv2, 0, 0, canv2.width, canv2.height);
    }
    if (this.image3Flag) {
      let canv3 = <HTMLCanvasElement> document.querySelector("#picture3");
      canvBack3.width = canv3.width;
      canvBack3.height = canv3.height;
      contextBack3.drawImage(canv3, 0, 0, canv3.width, canv3.height);
    }
    if (this.image4Flag) {
      let canv4 = <HTMLCanvasElement> document.querySelector("#picture4");
      canvBack4.width = canv4.width;
      canvBack4.height = canv4.height;
      contextBack4.drawImage(canv4, 0, 0, canv4.width, canv4.height);
    }

    this.isHorizontal = (window.orientation == 90);
    setTimeout(() => {
      if (this.image1Flag) {
        let newCanv1 = <HTMLCanvasElement> document.querySelector("#picture1");
        let newContext1 = newCanv1.getContext("2d");
        newContext1.drawImage(canvBack1, 0, 0, canvBack1.width, canvBack1.height, 0, 0, newCanv1.width, newCanv1.height);
      }
      if (this.image2Flag) {
        let newCanv2 = <HTMLCanvasElement> document.querySelector("#picture2");
        let newContext2 = newCanv2.getContext("2d");
        newContext2.drawImage(canvBack2, 0, 0, canvBack2.width, canvBack2.height, 0, 0, newCanv2.width, newCanv2.height);
      }
      if (this.image3Flag) {
        let newCanv3 = <HTMLCanvasElement> document.querySelector("#picture3");
        let newContext3 = newCanv3.getContext("2d");
        newContext3.drawImage(canvBack3, 0, 0, canvBack3.width, canvBack3.height, 0, 0, newCanv3.width, newCanv3.height);
      }
      if (this.image4Flag) {
        let newCanv4 = <HTMLCanvasElement> document.querySelector("#picture4");
        let newContext4 = newCanv4.getContext("2d");
        newContext4.drawImage(canvBack4, 0, 0, canvBack4.width, canvBack4.height, 0, 0, newCanv4.width, newCanv4.height);
      }
      this.setImages();
    }, 500);
  }
  
  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public inputIdClear(value) {
    this.init();
    this.id= '';
  }

  public inputQuantityClear(value) {
    this.changeQuantity = null;
  }

  public inputLocationClear(value) {
    this.changeLocation = '';
  }

  public changeInputId() {
    this.init();
    if (this.id) this.getDetailArrive();
  }

  public onClickIdQr() {
    this.qrFlagInit();
    this.qrIdFlag = true;
    this.cameraStart();
  }

  public onClickLocationQr() {
    this.qrFlagInit();
    this.qrLocationFlag = true;
    this.cameraStart();
  }

  public cameraCancel() {
    if (this.picFlag) {
      this.picNumber = -1;
    } else {
      clearInterval(this.cameraFunction);
    }
    this.qrFlagInit();
  }

  public cameraPicStart(value: number) {
    this.qrFlagInit();
    this.picFlag = true;
    this.picNumber = value;
    this.cameraStart();
  }

  public cameraPic() {
    const video = document.querySelector("video");
    const canvas = <HTMLCanvasElement> document.querySelector("#picture" + this.picNumber);
    canvas.height = 500;
    canvas.width = 500;
    const ctx = canvas.getContext("2d");
    video.pause();
    ctx.drawImage(video, 0, 0, 500, 500, 0, 0, canvas.width, canvas.height);
    if (this.picNumber == 1) this.image1Flag = true;
    if (this.picNumber == 2) this.image2Flag = true;
    if (this.picNumber == 3) this.image3Flag = true;
    if (this.picNumber == 4) this.image4Flag = true;
    this.qrFlagInit();
    this.picNumber = -1;
  }

  public create() {
    if (this.inputCheck()) {
      this.postCreateArrive();
    }
  }

  // Private Function ///////////////////////////////////////////////////////////
  private init() {
    this.changeQuantity = null;
    this.changeLocation = '';
    this.receive = new Receive();
    this.image1Flag = false;
    this.image2Flag = false;
    this.image3Flag = false;
    this.image4Flag = false;
    let aImg1 = <HTMLImageElement> document.getElementById('aImg1');
    let aImg2 = <HTMLImageElement> document.getElementById('aImg2');
    let aImg3 = <HTMLImageElement> document.getElementById('aImg3');
    let aImg4 = <HTMLImageElement> document.getElementById('aImg4');
    aImg1.src = '';
    aImg2.src = '';
    aImg3.src = '';
    aImg4.src = '';
    let canv1 = <HTMLCanvasElement> document.querySelector("#picture1");
    let canv2 = <HTMLCanvasElement> document.querySelector("#picture2");
    let canv3 = <HTMLCanvasElement> document.querySelector("#picture3");
    let canv4 = <HTMLCanvasElement> document.querySelector("#picture4");
    canv1.getContext('2d').clearRect(0, 0, 500, 500);
    canv2.getContext('2d').clearRect(0, 0, 500, 500);
    canv3.getContext('2d').clearRect(0, 0, 500, 500);
    canv4.getContext('2d').clearRect(0, 0, 500, 500);
  }

  private qrFlagInit() {
    this.qrIdFlag = false;
    this.qrLocationFlag = false;
    this.picFlag = false;
  }

  private cameraStart() {
    const p = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width:	{min: 500, max: 500},
    		height:	{min: 500, max: 500},
        aspectRatio: 1,
        facingMode: "environment",
        frameRate: { ideal: 5, max: 15 },
      }
    }).then((mediaStream) => {
      document.querySelector("video").srcObject = mediaStream;
    }).then(() => {
      if (!this.picFlag) this.onQrRead();
    });
  }

  private onQrRead() {
    const video = document.querySelector("video");
    let canv = document.createElement("canvas");
    canv.height = 500;
    canv.width = 500;
    const context = canv.getContext("2d");
    const countList = [];
    this.cameraFunction = setInterval(() => {
      context.drawImage(video, 0, 0, 500, 500);
      const imageData = context.getImageData(0, 0, 500, 500);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        this.codeInsart(code.data);
        video.pause();
        this.qrFlagInit();
        clearInterval(this.cameraFunction);
      }
    }, 500);
  }

  private codeInsart(code: string) {
    if (this.qrIdFlag) {
      this.id = code;
      this.getDetailArrive();
    } else if (this.qrLocationFlag) {
      this.changeLocation = code;
    }
  }

  private inputCheck(): boolean {
    this.errorMessage = '';
    if (!Common.isStringValue(this.id)) {
      this.errorMessage = CONST.MESSAGE.ARRIVE_MOVE.NONE_ID;
      $('#errorModal').modal();
    } else if (!Common.isStringValue(this.changeLocation)) {
      this.errorMessage = CONST.MESSAGE.ARRIVE_MOVE.NONE_LOCATION;
      $('#errorModal').modal();
    } else if (!Common.isNumberValue(this.changeQuantity)) {
      this.errorMessage = CONST.MESSAGE.ARRIVE_MOVE.NONE_QUANTITY;
      $('#errorModal').modal();
    } else if (this.changeQuantity > this.receive.quantity - this.receive.shipment.allQuantity) {
      this.errorMessage = CONST.MESSAGE.ARRIVE_MOVE.OVER_QUANTITY;
      $('#errorModal').modal();
    }
    return this.errorMessage == '';
  }

  private setImages() {
    if (this.receive.arrive.imagePath1 != null && this.receive.arrive.imagePath1 != '') {
      let aImg1 = <HTMLImageElement> document.getElementById('aImg1');
      aImg1.src = this.receive.arrive.imagePath1;
    }
    if (this.receive.arrive.imagePath2 != null && this.receive.arrive.imagePath2 != '') {
      let aImg2 = <HTMLImageElement> document.getElementById('aImg2');
      aImg2.src = this.receive.arrive.imagePath2;
    }
    if (this.receive.arrive.imagePath3 != null && this.receive.arrive.imagePath3 != '') {
      let aImg3 = <HTMLImageElement> document.getElementById('aImg3');
      aImg3.src = this.receive.arrive.imagePath3;
    }
    if (this.receive.arrive.imagePath4 != null && this.receive.arrive.imagePath4 != '') {
      let aImg4 = <HTMLImageElement> document.getElementById('aImg4');
      aImg4.src = this.receive.arrive.imagePath4;
    }
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.jans = init.jans;
      this.users = init.users;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private getDetailArrive() {
    let apiAddress: string = CONST.API.ARRIVE.DETAIL;
    let params = {id: this.id};
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      let receive = res as Receive;
      this.receive = receive;
      this.receive.riKubunId = 4;
      this.changeQuantity = receive.quantity - receive.shipment.allQuantity;
      this.setImages();
    }, error => {
      if (error.status == 410) {
        this.errorHandler.handleError(error);
        this.errorMessage = CONST.MESSAGE.ARRIVE_MOVE.NONE;
      } else {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      }
      this.id = '';
    });
  }

  private postCreateArrive() {
    let apiAddress: string = CONST.API.ARRIVE.MOVE;
    let params: ApiArrive = new ApiArrive();
    params.id = this.id;
    params.baseId = Common.baseId;
    params.createdUserId = Common.userId;
    params.updatedUserId = Common.userId;
    params.quantity = this.changeQuantity;
    params.location = this.changeLocation;
    if (this.image1Flag) {
      let canv1 = <HTMLCanvasElement> document.querySelector("#picture1");
      params.image1 = Common.imageDecode(canv1);
    }
    if (this.image2Flag) {
      let canv2 = <HTMLCanvasElement> document.querySelector("#picture2");
      params.image2 = Common.imageDecode(canv2);
    }
    if (this.image3Flag) {
      let canv3 = <HTMLCanvasElement> document.querySelector("#picture3");
      params.image3 = Common.imageDecode(canv3);
    }
    if (this.image4Flag) {
      let canv4 = <HTMLCanvasElement> document.querySelector("#picture4");
      params.image4 = Common.imageDecode(canv4);
    }
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      this.init();
      this.id = '';
      $('#successModal').modal();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
