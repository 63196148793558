import { Component } from '@angular/core';
import { AfterViewChecked } from '@angular/core';
import is from 'is_js';
import { Common } from './shared/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isLogin: boolean = false;
  public userId: string = Common.userId;

  constructor() {
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.isLogin = (Common.userId != null && Common.userId != '' && Common.userId != '9999');
    });
  }
}
