import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { OnsenModule } from 'ngx-onsenui';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';

import { EnvironmentUrlService } from './shared/services/environment-url.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './shared/error-pages/not-found/not-found.component';
import { InternalServerComponent } from './shared/error-pages/internal-server/internal-server.component';
import { MenuComponent } from './shared/menu/menu.component';

import { ROUTES } from './app.routes';
import { LoginComponent } from './main/login/login.component';
import { ArriveMoveComponent } from './main/arrive-move/arrive-move.component';
import { ArriveQrComponent } from './main/arrive-qr/arrive-qr.component';
import { ProcessListComponent } from './main/process-list/process-list.component';
import { ProcessInputComponent } from './main/process-input/process-input.component';
import { ReceiveJanComponent } from './main/receive-jan/receive-jan.component';
import { ReceiveListComponent } from './main/receive-list/receive-list.component';
import { ReceiveQrComponent } from './main/receive-qr/receive-qr.component';
import { ShipmentListComponent } from './main/shipment-list/shipment-list.component';
import { ShipmentInputComponent } from './main/shipment-input/shipment-input.component';
import { ShipmentDetailComponent } from './main/shipment-detail/shipment-detail.component';
import { StockListComponent } from './main/stock-list/stock-list.component';
import { StockDetailComponent } from './main/stock-detail/stock-detail.component';

import { UserListComponent } from './maintenance/users/user-list/user-list.component';
import { UserMaintenanceComponent } from './maintenance/users/user-maintenance/user-maintenance.component';
import { CompanyListComponent } from './maintenance/companies/company-list/company-list.component';
import { CompanyMaintenanceComponent } from './maintenance/companies/company-maintenance/company-maintenance.component';
import { JanListComponent } from './maintenance/jans/jan-list/jan-list.component';
import { JanMaintenanceComponent } from './maintenance/jans/jan-maintenance/jan-maintenance.component';

//import '../../node_modules/chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.js';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    InternalServerComponent,
    LoginComponent,
    ArriveMoveComponent,
    ArriveQrComponent,
    ProcessListComponent,
    ProcessInputComponent,
    ReceiveJanComponent,
    ReceiveListComponent,
    ReceiveQrComponent,
    ShipmentListComponent,
    ShipmentInputComponent,
    ShipmentDetailComponent,
    StockListComponent,
    StockDetailComponent,
    UserListComponent,
    UserMaintenanceComponent,
    CompanyListComponent,
    CompanyMaintenanceComponent,
    JanListComponent,
    JanMaintenanceComponent,
    MenuComponent,
  ],
  imports: [
    BrowserModule,
    ChartsModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    OnsenModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(ROUTES)
  ],
  providers: [
    EnvironmentUrlService,
    ErrorHandlerService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
