import { Component, OnInit, Input, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, ActivatedRoute} from '@angular/router';
import is from 'is_js';

import { RepositoryService } from './../../../shared/services/repository.service';
import { ErrorHandlerService } from './../../../shared/services/error-handler.service';

import { CONST } from './../../../shared/const';
import { Common } from './../../../shared/common';

import { Init } from './../../../_interfaces/init.model';
import { User } from './../../../_interfaces/user.model';
import { Company } from './../../../_interfaces/company.model';
import { Jan } from './../../../_interfaces/jan.model';
import { Value } from './../../../_interfaces/value.model';

@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class UserMaintenanceComponent implements OnInit {

  // public Data ////////////////////////////////
  public user: User = new User();
  public backData: User = new User();

  public idLogMessage: string = '';
  public nameLogMessage: string = '';
  public baseIdLogMessage: string = '';

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];

  // Etc Data //////////////////////////////////////
  public errorMessage: string = '';
  public modeMessage: string = '';

  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public mode: string = '';
  public isEditMode: boolean = false;
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  // Init Action //////////////////////////////////////////////////////////
  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
      this.route.params.subscribe(params => {
        this.mode = params['mode'];
        switch (this.mode) {
          case CONST.MODE.CREATE:
            this.modeMessage = '[新規入力]';
            this.isEditMode = false;
            break;
          case CONST.MODE.EDIT:
            this.modeMessage = '[入力修正]';
            this.isEditMode = true;
            this.getDetail(params['id']);
            break;
        }
      });
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action //////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public moveListView() {
    this.router.navigate([CONST.VIEW.USER_LIST]);
  }

  public inputIdClear() {
    this.user.id = null;
  }

  public inputNameClear() {
    this.user.name = null;
  }

  public save() {
    this.idLogMessage = '';
    this.nameLogMessage = '';
    this.baseIdLogMessage = '';

    if (!this.user.id) {
      this.idLogMessage = CONST.MESSAGE.USER_MAINTENANCE.NONE_ID;
      return;
    } else if (!this.user.name) {
      this.nameLogMessage = CONST.MESSAGE.USER_MAINTENANCE.NONE_NAME;
      return;
    } else if (!this.user.baseId) {
      this.baseIdLogMessage = CONST.MESSAGE.USER_MAINTENANCE.NONE_BASE_ID;
      return;
    }

    switch (this.mode) {
      case CONST.MODE.CREATE:
        this.create();
        break;
      case CONST.MODE.EDIT:
        this.update();
        break;
    }
  }

  public confirm() {
    if (this.mode == CONST.MODE.CREATE && (this.user.id || this.user.name || this.user.isAdmin || this.user.baseId)) {
      $('#actionModal').modal();
    } else if(this.mode == CONST.MODE.CREATE) {
      this.moveListView();
    } else {
      if (this.backData.name != this.user.name || this.backData.isAdmin != this.user.isAdmin
      || this.backData.baseId != this.user.baseId) {
        $('#actionModal').modal();
      } else {
        this.moveListView();
      }
    }
  }

  // Private Action ///////////////////////////////////////////////////////
  // Web Api //////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies;
      this.jans = init.jans;
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  public getDetail(id: string) {
    let apiAddress: string = CONST.API.USER.DETAIL;
    let res = this.repository.post(apiAddress, {id: id}).subscribe(res => {
      let user = res as User;
      this.user = user;
      this.backData = user;
    }, error => {
      this.moveListView();
    });
  }

  public create() {
    let apiAddress: string = CONST.API.USER.CREATE;
    this.repository.post(apiAddress, this.user).subscribe(res => {
      this.moveListView();
    }, error => {
      if (error.status == 409) {
        this.errorMessage = CONST.MESSAGE.USER_MAINTENANCE.DUPLICATE;
      } else {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      };
    });
  }

  public update() {
    let apiAddress: string = CONST.API.USER.UPDATE;
    this.repository.post(apiAddress, this.user).subscribe(res => {
      this.moveListView();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
