export class ApiArrive {
  id?: string;
  baseId?: number;
  imagePath1?: string;
  imagePath2?: string;
  imagePath3?: string;
  imagePath4?: string;
  image1?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  location?: string;
  quantity?: number;
  createdUserId?: string;
  updatedUserId?: string;

  constructor() {
    this.id = null;
    this.baseId = null;
    this.imagePath1 = null;
    this.imagePath2 = null;
    this.imagePath3 = null;
    this.imagePath4 = null;
    this.image1 = null;
    this.image2 = null;
    this.image3 = null;
    this.image4 = null;
    this.location = null;
    this.quantity = null;
    this.createdUserId = null;
    this.updatedUserId = null;
  }
}
