export class ApiStock {
  id?: string;
  baseId?: number;
  inventoryKubunId?: number;
  stockRemarks?: string;
  quantity?: number;
  location?: string;
  createdUserId?: string;
  updatedUserId?: string;

  constructor() {
    this.id = null;
    this.baseId = null;
    this.inventoryKubunId = null;
    this.stockRemarks = null;
    this.quantity = null;
    this.location = null;
    this.createdUserId = null;
    this.updatedUserId = null;
  }
}
