import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { SuccessModalComponent } from './modals/success-modal/success-modal.component';
import { DatepickerDirective } from './../shared/directives/datepicker.directive';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ActionModalComponent } from './modals/action-modal/action-modal.component';
import { FormsModule }   from '@angular/forms';


@NgModule({
  imports: [
        CommonModule,
        FormsModule
    ],
  declarations: [
        ErrorModalComponent,
        SuccessModalComponent,
        DatepickerDirective,
        ActionModalComponent,
    ],
  exports: [
        ErrorModalComponent,
        SuccessModalComponent,
        ActionModalComponent,
        DatepickerDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
