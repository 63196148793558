export class Stock {
  id?: string;
  baseId?: number;
  remarks?: string;
  createdUserId?: string;
  createdAt?: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  isDeleted?: boolean;

  createdUserName?: string;
  updatedUserName?: string;

  constructor() {
    this.id = null;
    this.baseId = null;
    this.remarks = null;
    this.createdUserId = null;
    this.createdAt = null;
    this.updatedUserId = null;
    this.updatedAt = null;
    this.isDeleted = null;
    this.createdUserName = null;
    this.updatedUserName = null;
  }
}
