import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import is from 'is_js';

import { RepositoryService } from './../../shared/services/repository.service';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';

import { Init } from './../../_interfaces/init.model';
import { Receive } from './../../_interfaces/receive.model';
import { ApiReceive } from './../../_interfaces/api-receive.model';
import { User } from './../../_interfaces/user.model';
import { Company } from './../../_interfaces/company.model';
import { Jan } from './../../_interfaces/jan.model';
import { Value } from './../../_interfaces/value.model';

@Component({
  selector: 'app-receive-qr',
  templateUrl: './receive-qr.component.html',
  styleUrls: ['./receive-qr.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ReceiveQrComponent implements OnInit {

  // Input Data //////////////////////////////////////
  public qrCode: string = '';
  public receive: Receive = new Receive();
  public arrivalAtStr: string = '';

  // Master Data //////////////////////////////////////
  public categories: Value[] = [];
  public inchs: Value[] = [];
  public kubun1s: Value[] = [];
  public kubun2s: Value[] = [];
  public inventoryKubuns: Value[] = [];
  public riKubuns: Value[] = [];
  public bases: Value[] = [];
  public feceses: Value[] = [];
  public companies: Company[] = [];
  public jans: Jan[] = [];
  public users: User[] = [];

  // Etc Data ////////////////////////////////////////
  public isMobile: boolean = is.mobile();
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public version: string = CONST.VERSION;
  public menuState: string = CONST.MENU.OUT;
  public errorMessage: string = '';

  constructor(
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) { }

  ngOnInit() {
    if (Common.userId != '' || CONST.DEBUG) {
      this.getInit();
      this.isHorizontal = (window.orientation == 90);
    } else {
      this.router.navigate([CONST.VIEW.LOGIN]);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }

  // View Action ////////////////////////////////////////////////////////////////
  public openMenu() {
    this.menuState = this.menuState === CONST.MENU.OUT ? CONST.MENU.IN : CONST.MENU.OUT;
  }

  public closeMenu() {
    this.menuState = CONST.MENU.OUT;
  }

  public logout() {
    this.router.navigate([CONST.VIEW.LOGIN]);
  }

  public qrClear() {
    this.qrCode = null;
  }

  public nameClear() {
    this.receive.name = null;
  }

  public categoryIdClear() {
    this.receive.categoryId = null;
  }

  public quantityClear() {
    this.receive.quantity = null;
  }

  public inchIdClear() {
    this.receive.inchId = null;
  }

  public purchaseIdClear() {
    this.receive.purchaseId = null;
  }

  public arrivalAtClear() {
    this.receive.arrivalAt = null;
  }

  public kubun1IdClear() {
    this.receive.kubun1Id = null;
  }

  public kubun2IdClear() {
    this.receive.kubun2Id = null;
  }

  public inventoryKubunIdClear() {
    this.receive.inventoryKubunId = null;
  }

  public riKubunIdClear() {
    this.receive.riKubunId = null;
  }

  public arrivalRemarksClear() {
    this.receive.arrivalRemarks = null;
  }

  public save() {
    if (this.inputCheck()) {
      this.postSaveReceive();
    }
  }

  // Private Function ///////////////////////////////////////////////////////////
  private inputCheck(): boolean {
    this.errorMessage = '';
    if (!Common.isNumberValue(this.receive.categoryId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_CATEGORY;
    } else if (!Common.isStringValue(this.receive.name)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_NAME;
    } else if (!Common.isNumberValue(this.receive.inchId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_INCH;
    } else if (!Common.isNumberValue(this.receive.quantity)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_QUANTITY;
    } else if (this.receive.quantity <= 0) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.ERROR_QUANTITY;
    } else if (!Common.isNumberValue(this.receive.kubun1Id)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_KUBUN_FIRST;
    } else if (!Common.isNumberValue(this.receive.kubun2Id)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_KUBUN_SECOND;
    } else if (!Common.isNumberValue(this.receive.inventoryKubunId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_INVENTORY_KUBUN;
    } else if (!Common.isNumberValue(this.receive.riKubunId)) {
      this.errorMessage = CONST.MESSAGE.RECEIVE_QR.NONE_RI_KUBUN;
    }
    if (this.errorMessage != '') $('#errorModal').modal();
    return this.errorMessage == '';
  }

  private setParams(): ApiReceive {
    let purchaseId = this.receive.purchaseId;
    for (let i = 0; i < this.companies.length; i++) {
      if (this.companies[i].name == this.receive.purchaseName) {
        purchaseId = this.companies[i].id;
      }
    }

    let apiReceive: ApiReceive = new ApiReceive();
    apiReceive.id = '';
    apiReceive.categoryId = this.receive.categoryId;
    apiReceive.inchId = this.receive.inchId;
    apiReceive.name = this.receive.name;
    apiReceive.baseId = Common.baseId;
    apiReceive.janId = this.receive.janId;
    apiReceive.arrivalAt = new Date(this.arrivalAtStr);
    apiReceive.quantity = this.receive.quantity;
    apiReceive.purchaseId = purchaseId;
    apiReceive.purchaseName = this.receive.purchaseName;
    apiReceive.kubun1Id = this.receive.kubun1Id;
    apiReceive.kubun2Id = this.receive.kubun2Id;
    apiReceive.inventoryKubunId = this.receive.inventoryKubunId;
    apiReceive.riKubunId = this.receive.riKubunId;
    apiReceive.location = "9999";
    apiReceive.arrivalRemarks = this.receive.arrivalRemarks;
    apiReceive.createdUserId = Common.userId;
    apiReceive.updatedUserId = Common.userId;
    return apiReceive;
  }

  // Web Api ////////////////////////////////////////////////////////////////////
  private getInit() {
    let apiAddress: string = CONST.API.INIT.GET_DATA;
    let res = this.repository.getData(apiAddress).subscribe(res => {
      let init = res as Init;
      this.categories = init.categories;
      this.inchs = init.inchs;
      this.kubun1s = init.kubun1s;
      this.kubun2s = init.kubun2s;
      this.inventoryKubuns = init.inventoryKubuns;
      this.riKubuns = init.riKubuns;
      this.bases = init.bases;
      this.feceses = init.feceses;
      this.companies = init.companies.filter(company => {return company.isPurchase;});
      this.jans = init.jans;
      this.users = init.users;
      let date = new Date();
      this.arrivalAtStr = Common.getStringFromDate(date, 'YYYY-MM-DD');
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }

  private postSaveReceive() {
    let apiAddress: string = CONST.API.RECEIVE.CREATE;
    let params: ApiReceive = this.setParams();
    let res = this.repository.post(apiAddress, params).subscribe(res => {
      this.qrCode = null;
      this.receive = new Receive();
      let date = new Date();
      this.arrivalAtStr = Common.getStringFromDate(date, 'YYYY-MM-DD');
      $('#successModal').modal();
    }, error => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    });
  }
}
