import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from './../../shared/services/repository.service';
import { EnvironmentUrlService } from './../../shared/services/environment-url.service';
import { CONST } from './../../shared/const';
import { Common } from './../../shared/common';
import is from 'is_js';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public isAdmin: boolean = Common.isAdmin;
  public isPad: boolean = is.ipad() || is.tablet();
  public isHorizontal: boolean = false;
  public baseName: string = Common.baseName;
  public isMobile: boolean = is.mobile();

  constructor(
    private router: Router,
    private repository: RepositoryService,
    private envUrl: EnvironmentUrlService
  ) { }

  ngOnInit() {
    this.isHorizontal = (window.orientation == 90);
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.isHorizontal = (window.orientation == 90);
  }
  // Main View ////////////////////////////////////////////////////////////////
  public moveReceiveList() {
    this.router.navigate([CONST.VIEW.RECEIVE_LIST]);
  }
  public moveReceiveJan() {
    this.router.navigate([CONST.VIEW.RECEIVE_JAN]);
  }
  public moveReceiveQr() {
    this.router.navigate([CONST.VIEW.RECEIVE_QR]);
  }
  public moveArriveQr() {
    this.router.navigate([CONST.VIEW.ARRIVE_QR]);
  }
  public moveArriveMove() {
    this.router.navigate([CONST.VIEW.ARRIVE_MOVE]);
  }
  public moveShipmentList() {
    this.router.navigate([CONST.VIEW.SHIPMENT_LIST]);
  }
  public moveStockList() {
    this.router.navigate([CONST.VIEW.STOCK_LIST]);
  }
  public moveProcessList() {
    this.router.navigate([CONST.VIEW.PROCESS_LIST]);
  }

  // Maintenance View /////////////////////////////////////////////////////////
  public moveUserList() {
    this.router.navigate([CONST.VIEW.USER_LIST]);
  }
  public moveCompanyList() {
    this.router.navigate([CONST.VIEW.COMPANY_LIST]);
  }
  public moveJanList() {
    this.router.navigate([CONST.VIEW.JAN_LIST]);
  }
}
